import React from 'react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useAuth from '../../hooks/useAuth';

function downloadPdf() {
  // const input = document.getElementById('pdf-content');
  // const pdf = new jsPDF('p', 'pt', 'a4');
  // let currentPage = 1;

  // html2canvas(input).then((canvas) => {
  //   const imgData = canvas.toDataURL('image/png');

  //   // Add first page
  //   pdf.addPage();
  //   pdf.setPage(currentPage);
  //   pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, null, 'FAST');

  //   // Add links to other pages
  //   currentPage++;
  //   pdf.addPage();
  //   pdf.setPage(currentPage);
  //   pdf.text(10, 20, 'Page 2');
  //   pdf.addLink(10, 20, 30, 10, { page: "www.google.com" });

  //   currentPage++;
  //   pdf.addPage();
  //   pdf.setPage(currentPage);
  //   pdf.text(10, 20, 'Page 3');
  //   pdf.addLink(10, 20, 30, 10, { page: "www.google.com" });

  //   // Save PDF
  //   pdf.save('my-pdf-file.pdf');
  // });
}


const DigitalCard = () => {
const { auth } = useAuth()
  return (
    <div>
      <button className={`btn btn-primary btn-pill col-md-2 ${auth.theme==='companyTheme'?'':'agent'}`} onClick={downloadPdf}>Export Digital Card</button>
      {/* <div id="pdf-content">
        <PdfContent />
      </div> */}
    </div>
  )
}

// const PdfContent = () => {
//   return (
//     <div style={{ backgroundColor: 'white', padding: '1rem' }}>
//       <h1 style={{ color: 'red', marginBottom: '1rem' }}>My PDF Content</h1>
//       <p style={{ color: 'blue' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet metus non augue mollis maximus eu ac ex. Suspendisse lobortis magna in nulla tincidunt auctor. Nullam lobortis sagittis libero, eget malesuada massa consequat quis. Sed euismod ante quam, id egestas dolor congue eget.</p>
//       <img style={{ maxWidth: '50%' }} src="./logo512.png" alt="Image 1" />
//       <img style={{ maxWidth: '50%' }} src="./logo512.png" alt="Image 2" />
//     </div>
//   )
// }

export default DigitalCard