import React from 'react'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import useCustomerServices from '../../../services/customerServices'
import Swal from 'sweetalert2'
import useAuth from '../../../hooks/useAuth'

const CustomerAddForm = (props) => {
    const {handleClose, datas, refresh} = props

    const [customerName, setCustomerName] = useState(datas.name)
    const [customerEmail, setCustomerEmail] = useState(datas.email)
    const [customerMobile, setCustomerMobile] = useState(datas.mobile)
    const [customerMobile2, setCustomerMobile2] = useState(null)

    const { postCreateCustomer } = useCustomerServices()

    const { auth } = useAuth()

    const handleSubmit = async (e) => {
        e.preventDefault()
    
        try {
          const data = JSON.stringify({
            name: customerName,
            email: customerEmail,
            mobile: customerMobile,
            mobile2: customerMobile2,
          });
          const response = await postCreateCustomer(datas.id, data)
        //   console.log(response?.data)
          if (response.success) {
            Swal.fire("", response?.message, "success")
            refresh()
            handleClose()
          }
          else{
            Swal.fire("", response?.message, "error")
            handleClose()
          }
        }
        catch (err) {
            Swal.fire("", "Something went wrong!!", "error");
          //console.log(err)
        }
      }

    return (
        <div className="">
            <div className="row">
                <div className="h3 fw-bolder">Add Customer</div>
            </div>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row my-4">
                            <div className="col-md-8 px-1">
                                <Form.Group className='' controlId='leadName'>
                                    <Form.Label className='fw-bolder'>Name</Form.Label>
                                    <Form.Control className='py-2' placeholder="Hello" value={customerName} onChange={(e)=>setCustomerName(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="col-md-4 px-1">
                                <Form.Group className='' controlId='leadMobile'>
                                    <Form.Label className='fw-bolder'>Mobile Number</Form.Label>
                                    <Form.Control className='py-2' placeholder="1234567890" value={customerMobile} onChange={(e)=>setCustomerMobile(e.target.value)}/>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-md-5 px-1">
                                <Form.Group className='' controlId='leadSource'>
                                    <Form.Label className='fw-bolder'>Alternate Mobile</Form.Label>
                                    <Form.Control className='py-2' placeholder="Direct Enquiry" onChange={(e)=>setCustomerMobile2(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="col-md-7 px-1">
                                <Form.Group className='' controlId='leadMail'>
                                    <Form.Label className='fw-bolder'>Email</Form.Label>
                                    <Form.Control className='py-2' placeholder="user@example.com" value={customerEmail} onChange={(e)=>setCustomerEmail(e.target.value)}/>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row staff-btn-container">
                    <div className="col-md-6"></div>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className="col-md-6 pb-2">
                                <div onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</div>
                            </div>
                            <div className="col-md-6">
                                <button type='submit' className={`btn btn-primary staff-btn col-12 ${auth.theme==='companyTheme'?'':'agent'}`}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default CustomerAddForm