import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { FiDownload } from "react-icons/fi";
import ReactPlayer from "react-player";
import { REC_URL } from "../../api/axios";
import useLeadServices from "../../services/leadServices";
import "./CallLogs.css"

const CallLogs = ({ type, content }) => {
  const [callLog, setCallLog] = useState("");
  const { getCallLogs } = useLeadServices();
  var mobile = content?.mobile
  mobile = mobile.slice(-10)

  useEffect(() => {
    getCallList();
  }, [content]);

  const getCallList = async () => {
    try {
      const data = {
        search: mobile,
      }
      const response = await getCallLogs(data);
      if (response?.success) {
        setCallLog(response?.data?.content);
      }
    } catch {}
  };
  const getDate = (data) => {
    return new Date(data).toString().slice(4, 24);
  };
  
  const getTime = (data) => {
    return new Date(data).toLocaleTimeString()
  }

  const getStatus = (data) => {
    if (data == "CANCEL"|| data == "Cancel"|| data == "NOANSWER")  {
        return "NO RESPONSE"
    }
    return data
  }

  const getDuration = (data) => {
    if (data > 3600) {
        let hours = Math.floor(data / 3600)
        let min = Math.floor((data % 3600) / 60)
        let sec = ((data % 3600) % 60)
        let time = hours + "h " + min + "m " + sec + "s"
        return time;
    }
    else if (data > 60) {
        let min = Math.floor(data / 60)
        let sec = data % 60
        let time = min + "m " + sec + "s"
        return time;
    }
    else {
        let time = data + "s"
        return time;
    }
  }

  return (
    <div className="field-data-card px-3">
      {window.innerWidth > 768 ?
        <table className="table table-info table-striped">
          <thead className="">
            <tr className="row my-3">
              <th className="col-1">Call Type</th>
              <th className="col-2 pe-0">Call Status</th>
              <th className="col-2 px-0">Initiated time</th>
              <th className="col-2 px-0">End time</th>
              <th className="col-1 px-0">Duration</th>
              <th className="col-4 px-0">Recordings</th>
            </tr>
          </thead>
          <tbody>
            {callLog &&
              callLog.map((data, index) => {
                return (
                  <tr className="row fs-6" key={index}>
                    <td className="col-md-1 col-12 d-flex align-items-center">
                      {data.call_type || ""}
                      </td>
                    <td className="col-md-2 col-12 pe-0 d-flex align-items-center">
                      {getStatus(data.call_status) || ""}
                    </td>
                    <td className="col-md-2 col-12 px-0 d-flex align-items-center">
                      {getTime(data?.start_time) || ""}
                    </td>
                    <td className="col-md-2 col-12 px-0 d-flex align-items-center">
                      {getTime(data?.end_time) || ""}
                    </td>
                    <td className="col-md-1 col-12 px-0 d-flex align-items-center">
                      {getDuration(data?.call_duration)}
                    </td>
                    {data?.call_duration?
                    <td className="col-md-4 col-12 ps-0 d-flex">
                        <ReactPlayer
                          url={REC_URL + data?.recording_url}
                          width="40rem"
                          height="2.5rem"
                          playing={false}
                          controls={true}
                          config={
                            {
                              file: {
                                attributes: {
                                  controlsList: 'nodownload' 
                                } 
                              } 
                            }
                          }
                        />
                        &nbsp;
                        <a
                          href={REC_URL + data?.recording_url}
                          className="btn btn-success"
                        >
                          <FiDownload size="15px" />
                        </a>
                    </td>:
                    <td className="col-md-4 col-12 ps-0"></td>}
                  </tr>
                );
              })}
          </tbody>
        </table>
        :
        <>
        {callLog &&
          callLog.map((data, index) => {
            return (
              <div className="followup-card responsive col-12 p-2 pe-0 my-2" key={index}>
                <table className=" my-2 followup-card-title text-dark p-1 pe-5 ps-3 ">
                  <tbody>
                    <tr>
                      <th>Call Type</th>
                      <td>{data.call_type || ""}</td>
                    </tr>
                    <tr>
                      <th>Call Status</th>
                      <td>{getStatus(data.call_status) || ""}</td>
                    </tr>
                    <tr>
                      <th>Initiated</th>
                      <td>{getTime(data?.start_time) || ""}</td>
                    </tr>
                    <tr>
                      <th className="">End</th>
                      <td>{getTime(data?.end_time) || ""}</td>
                    </tr>
                    <tr>
                      <th>Duration</th>
                      <td>{getDuration(data?.call_duration)} s</td>
                    </tr>
                    {data?.call_duration&&
                    <tr>                    
                      <td className="" colSpan={2}>
                        <div className="d-flex">
                          <ReactPlayer
                            url={REC_URL + data?.recording_url}
                            width="14.3rem"
                            height="2.5rem"
                            playing={false}
                            controls={true}
                            config={
                              {
                                file: {
                                  attributes: {
                                    controlsList: 'nodownload' 
                                  } 
                                } 
                              }
                            }
                          />
                          &nbsp;
                          <a
                            href={REC_URL + data?.recording_url}
                            className="btn btn-success"
                          >
                            <FiDownload size="15px" />
                          </a>
                        </div>
                      </td>
                    </tr>||<></>}
                  </tbody>
                </table>
              </div>
            );
          })}
          </>
        }
    </div>
  );
};

export default CallLogs;
