import { Button, Modal } from "react-bootstrap"
import { BsPersonAdd } from 'react-icons/bs';
import "./Customer.css"
import CustomerAddForm from "./components/CustomerAddForm";
import { useState } from "react";
import useCustomerServices from "../../services/customerServices";
import { useEffect } from "react";
import CustomerTable from "./components/CustomerTable";


import { Dropdown, Icon } from 'semantic-ui-react'
import { DatePicker } from '@mui/x-date-pickers'
import { format } from 'date-fns'
import { Pagination } from "../../components/pagination/Pagination";
import { AiOutlineSearch } from 'react-icons/ai'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import { useReducer } from "react";
import useAuth from "../../hooks/useAuth";




const initialState = {
  dateSort: null,
  nameSort: null,
  date: '',
  from: '',
  to: '',
  dateType: 'Filter',
  showSearch: false,
  showPicker: false,
  searchQuery: "",
  isSearch: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "dateSort":
      return { ...state, dateSort: action.payload };
    case "nameSort":
      return { ...state, nameSort: action.payload };
    case 'date':
      return { ...state, date: action.payload, from: '', to: '' };
    case 'from':
      return { ...state, from: action.payload, date: '' };
    case 'to':
      return { ...state, to: action.payload, date: '' };
    case 'dateType':
      return { ...state, dateType: action.payload };
    case 'showPicker':
      return { ...state, showPicker: action.payload };
    case 'searchQuery':
      return { ...state, searchQuery: action.payload }
    case 'isSearch':
      return { ...state, isSearch: action.payload };

    case 'reset':
      return { ...initialState };
  }
}
const Customer = () => {
  const [show, setShow] = useState(false)
  const [statusList, setStatusList] = useState("")
  const [customerList, setCustomerList] = useState("")
  const [crntpg, setCrntpg] = useState(1)
  const [limit, setLimit] = useState(0)
  const [item, setItem] = useState(25)

  const [filter, setFilter] = useState("Filter")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")

  const { auth } = useAuth()

  const [filters, dispatch] = useReducer(reducer, initialState)

  const handleClose = () => {
    setShow(false)
  }

  const { getAllCustomers, getActiveStatus } = useCustomerServices()

  useEffect(() => {
    getStatusList()
  }, [])
  useEffect(() => {
    getCustomerList()
  }, [
    filters.isSearch,
    crntpg,
    filters.dateSort,
    filters.nameSort
  ])
  useEffect(() => {
    if (filters.from == "" && filters.to == "") {
      getCustomerList();
    }
  }, [
    filters.date
  ])

  const getCustomerList = async () => {
    const form = {
      date: filters.date,
      to: filters.to,
      from: filters.from,
      name_sort: filters.nameSort,
      date_sort: filters.dateSort,
      page: crntpg,
      search: filters.isSearch ? filters.searchQuery : "",
      user_id: auth.staffId
    }
    // let form= {...data}
    // form.page = crntpg
    // form.item = item
    const response = await getAllCustomers(form && form)
    if (response !== null) {
      setCustomerList(response?.data?.customer)
      setLimit(response?.data?.total_page)
    }
  }

  const getStatusList = async () => {
    const response = await getActiveStatus()
    if (response !== null) {
      setStatusList(response.data)
    }
  }

  const handleSort = (e, sortType) => {
    e.preventDefault()
    if (sortType === 'date') {
      switch (filters.dateSort) {
        case null:
          dispatch({ type: 'dateSort', payload: 1 })
          break
        case 1:
          dispatch({ type: 'dateSort', payload: 2 })
          break
        case 2:
          dispatch({ type: 'dateSort', payload: null })
          break
      }
    }
    if (sortType === 'name') {
      switch (filters.nameSort) {
        case null:
          dispatch({ type: 'nameSort', payload: 1 })
          break
        case 1:
          dispatch({ type: 'nameSort', payload: 2 })
          break
        case 2:
          dispatch({ type: 'nameSort', payload: null })
          break
      }
    }
  }

  const handleFilter = (data) => {
    dispatch({ type: 'dateType', payload: data })

    if (data === 'Date' || data === 'Range') {
      dispatch({ type: "showPicker", payload: true })
    }
    else if (data == "Today") {
      dispatch({ type: 'date', payload: format(new Date(), 'yyyy-MM-dd') })
    }
    else if (data == "Yesterday") {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      dispatch({ type: 'date', payload: format(date, 'yyyy-MM-dd') })
    }
  }

  const handleDate = async (e) => {
    dispatch({ type: 'date', payload: format(e['$d'], 'yyyy-MM-dd') })
    handlePickerClose();
  }

  const handleDateRange = async (e) => {
    e.preventDefault()
    getCustomerList()
    handlePickerClose();
  }

  const handleSearch = () => {
    if (filters.searchQuery != "") {
      dispatch({ type: 'isSearch', payload: true })
    }
    else {
      getCustomerList()
    }
  };

  const handlePickerClose = () => {
    dispatch({ type: "showPicker", payload: false })
  };


  return (
    <div className="">
      <div className="row pe-1  align-items-center">
        <div className="col-md-2 col-4 px-0 ms-0 row">
          <div className="col-md col my-3 ms-md-0 ms-3 ps-0 pe-0">
            <Dropdown id="dropdown-basic-button"
              className={`btn btn-sm btn-primary ${auth.theme === 'companyTheme' ? '' : 'agent'}`}
              text={filters.dateType}>
              <Dropdown.Menu className='mt-2 p-3'>
                <Dropdown.Item onClick={() => handleFilter("Today")}>Today</Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Yesterday")}>Yesterday</Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Date")}>Specific day</Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilter("Range")}>Date Range</Dropdown.Item>
              </Dropdown.Menu></Dropdown>
          </div>
        </div>
        {(filters.dateType != "Filter" || filters.isSearch) &&
          <div className="col-2 ms-1 pe-0 ps-0 col-md-1 clear-filter my-3">
            <Button onClick={() => dispatch({ type: 'reset' })} variant="danger" size='sm'>
              {window.innerWidth > 768 ? 'Reset' : <Icon name='x' />}
            </Button>
          </div>}
        {/* <Modal
          show={filters.showPicker}
          onHide={handlePickerClose}
          centered
          size="sm"
          contentClassName="upload-modal"
        >
          <Modal.Body className="p-4">
            {filters.dateType === "Date" &&
              <div className="col-md-12 my-3 ps-md-0">
                <DatePicker
                  className='lead-datepicker col-12 px-2'
                  onChange={(e) => handleDate(e)}
                  format="DD-MMM-YYYY"
                />
              </div>
            }
            {filters.dateType === "Range" &&
              <>
                <div className="col-md-12 my-3 ps-md-0">
                  <DatePicker
                    className='lead-datepicker col-12 px-2 '
                    onChange={(e) => dispatch({ type: 'from', payload: format(e['$d'], 'yyyy-MM-dd') })}

                    format="DD-MMM-YYYY"
                  />
                </div>
                <div className="col-md-12 my-3 ps-md-1">
                  <DatePicker
                    className='lead-datepicker col-12 px-2 px-md-0'
                    onChange={(e) => dispatch({ type: 'to', payload: format(e['$d'], 'yyyy-MM-dd') })}
                    format="DD-MMM-YYYY"
                  />
                </div>
                <div className='col-md-12 my-3 ps-md-1'>
                  <button className='btn btn-pill btn-outline-primary col-12 px-2 px-md-0' onClick={(e) => handleDateRange(e)}>search</button>
                </div>
              </>
            }
          </Modal.Body>
        </Modal> */}
        <div id="" className={`${(filters.dateType != "Filter") || filters.isSearch ? 'col-md' : 'col-md'} col-12 my-3 me-3 text-md-end align-items-center d-md-flex justify-content-end text-center`}>
          {window.innerWidth > 768 ?
            <div className={"ui action input search-bar show"}>
              <input value={filters.searchQuery} onChange={(e) => dispatch({ type: "searchQuery", payload: e.target.value })} type="text" placeholder="Search..." className="rounded-input" />
              <button onClick={handleSearch} className="ui button rounded-button"><AiOutlineSearch /></button>
            </div>
            :
            <div className={"ui action input search-bar show mb-3"}>
              <input value={filters.searchQuery} onChange={(e) => dispatch({ type: "searchQuery", payload: e.target.value })} type="text" placeholder="Search..." className="rounded-input" />
              <button onClick={handleSearch} className="ui button rounded-button"><AiOutlineSearch /></button>
            </div>
          }
        </div>
      </div>
      {filters.showPicker &&
        <div className="row mb-3 me-0">
          {filters.dateType === "Date" &&
            <div className="col-md-2 col-12 ps-md-0">
              <DatePicker
                className='lead-datepicker col-12 pe-2'
                onChange={(e) => handleDate(e)}
                format="DD-MMM-YYYY"
              />
            </div>
          }
          {filters.dateType === "Range" &&
            <>
              <div className="col-md-2 col-12 ps-md-0 my-md-0 my-1">
                <DatePicker
                  className='lead-datepicker col-12 pe-2 ps-3 ps-md-0'
                  onChange={(e) => dispatch({ type: 'from', payload: format(e['$d'], 'yyyy-MM-dd') })}
                  format="DD-MMM-YYYY"
                />
              </div>
              <div className="col-md-2 col-12 ps-md-1 my-md-0 my-1">
                <DatePicker
                  className='lead-datepicker col-12 pe-2 ps-3 px-md-0'
                  onChange={(e) => dispatch({ type: 'to', payload: format(e['$d'], 'yyyy-MM-dd') })}
                  format="DD-MMM-YYYY"
                />
              </div>

              <div className='col-md-1 col-12 ps-md-1 my-md-0 my-1 d-flex justify-content-center'>
                <button 
                  className='btn btn-outline-primary col-4 col-md-12 px-2 py-2' 
                  onClick={(e) => handleDateRange(e)}
                >search</button>
              </div>
            </>
          }
        </div>
      }
      <div className="row px-2 ps-md-0 pe-md-4">
        <div className="card customer-table-card">
          <div className="card-body table-responsive pb-5">
            <table className="table table-striped pb-5 mb-5">
              <thead className="border-4">
                <tr className="">
                  <th>Sl No.</th>
                  <th>
                    Name
                    &nbsp;
                    <span onClick={(e) => handleSort(e, 'name')}>
                      {
                        filters.nameSort === 1 ?
                          <FaSortUp /> :
                          filters.nameSort === 2 ?
                            <FaSortDown /> :
                            <FaSort />
                      }
                    </span>
                  </th>
                  <th>Phone</th>
                  <th>Call</th>
                  <th>E-mail</th>
                  <th className="pe-5 px-0">
                    Date
                    &nbsp;
                    <span onClick={(e) => handleSort(e, 'date')}>
                      {
                        filters.dateSort === 1 ?
                          <FaSortUp /> :
                          filters.dateSort === 2 ?
                            <FaSortDown /> :
                            <FaSort />
                      }
                    </span>
                  </th>
                  <th className="text-center">Payment</th>
                  <th className="text-center">Status</th>
                  <th className="pe-4">Active</th>
                </tr>
              </thead>
              <tbody className="customer-table">
                <tr></tr>
                <tr><td></td></tr>
                {customerList && customerList.map(
                  (data, index) => {
                    return (
                      <CustomerTable
                        key={index}
                        index={(item * (crntpg - 1)) + index}
                        data={data}
                        statusList={statusList}
                        refresh={getCustomerList}
                      />
                    )
                  }
                )
                }
              </tbody>
            </table>
          </div>
          {auth.isSwitch == 'false' &&
            <div
              onClick={() => setShow(true)}
              className={"button add-position " + (window.innerWidth > 768 ? 'lap-view' : 'mobile-view')}
            >
              <div className="customer-add">
                <span className="card mouse-pointer p-3 d-inline">
                  <BsPersonAdd className="px-0" size="30px" /> Add Customer
                </span>
              </div>
            </div>
          }
          <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            contentClassName="lead-modal"
          >
            <Modal.Body className='p-5'>
              <CustomerAddForm handleClose={handleClose} refresh={() => getCustomerList()} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      {limit > 1 && <Pagination {...{ crntpg, setCrntpg, limit }} />}
    </div>
    // :
    // <Navigate to="/" />
  )
}

export default Customer