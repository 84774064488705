
import "./Dashboard.css"

import { useEffect, useState } from "react"
import useAuth from "../../hooks/useAuth"
import DashboardCompany from "./components/DashboardCompany"
import DashboardAgent from "./components/DashboardAgent"
import useCompanyServices from '../../services/companyServices'
import useAgentServices from '../../services/agentServices'
import useStaffServices from '../../services/staffServices'
import { MEDIA_URL } from "../../api/axios"
import useBaseServices from "../../services/baseServices"
import AdminDashboard from "../admin/pages/dashboard/AdminDashboard"
import useAdminServices from "../../services/adminServices"


const Dashboard = () => {
  const [cardData, setCardData] = useState([])
  const [sourceData, setSourceData] = useState()
  const [sourceCount, setSourceCount] = useState()
  const [callPercentage, setCallPercentage] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [companyData, setCompanyData] = useState()
  const [weeks, setWeeks] = useState()
  const [dayOpen, setDayOpen] = useState()
  const [dayClose, setDayClose] = useState()
  const [months, setMonths] = useState()
  const [monthClosed, setMonthClosed] = useState()
  const [monthTotal, setMonthTotal] = useState()
  const [filter, setFilter] = useState(0)
  const [status, setStatus] = useState()
  const [statusCount, setStatusCount] = useState()
  const [show, setShow] = useState(true)

  const { auth,setAuth } = useAuth()

  const { getStaffProfile, getDashboard } = useBaseServices()
  const { getCompanyProfile } = useCompanyServices()
  const { getAdminProfile } = useAdminServices()


  useEffect(() => {
    getDashboards()
  }, [filter])

  // useEffect(()=>{
  //   getProfile()
  // },[])

  // const getProfile = async () => {
  //   var image;
  //   if(auth.role==='Company'){
  //     const response = await getCompanyProfile()
  //     image = response.data.logo
  //   }
  //   else if(auth.role==='Admin'){
  //     const response = await getAdminProfile()
  //     image = response.data.logo
  //   }
  //   else{
  //     const response = await getStaffProfile()
  //     image = response.data.image
  //   }
  //   // console.log(image)
  //   localStorage.setItem("image", MEDIA_URL+image)
  //   setAuth({ image})

  // }

  const getDashboards = async () => {
    // var response = null
    var data
    // console.log(filter)
    if (filter === 0){
      data={
        user_id:auth.staffId
      }
    }
    else{
      data = {
        user_id:auth.staffId,
        day:filter
      }
    }
    const response = await getDashboard(data)
    if(auth.role==='Admin'){
      console.log(response.data)
      setCompanyData(response.data.comp_card)
      setCallPercentage(response?.data.call_chart.percentage)
      setCompanyList(response?.data.call_chart.company)
    }else{
      setSourceData(response.data.source)
      setSourceCount(response.data.source_count)
      setCardData(response.data.card)
      setWeeks(response.data.week['days'])
      setDayOpen(response.data.week['daily_leads'])
      setDayClose(response.data.week['daily_closed'])
      setMonths(response.data.month['months'])
      setMonthClosed(response.data.month['monthly_closed'])
      setMonthTotal(response.data.month['monthly_total'])
      setStatus(response.data.status)
      setStatusCount(response.data.status_count)
    }
    // response.data.source?setSource(response.data.source):setSource('')  
    
  }
  //charts

  // const createGradient = () => {
  //   const gradient = createLinearGradient('to top', rgba(140, 39, 165,1),rgba(140, 39, 165,0.5))
  // }

  //line charts
  const lineOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      datalabels:{display:false},
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        backgroundColor: '#3E66CD',
        yAlign: 'bottom',
        boxWidth: 20,
        padding: 10,
        bodySpacing: 10
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          drawTicks: false, //hide datatype lines
        },
        beginAtZero: true,
        ticks: {
          display: false //hide the datatype values
        }
      },
    },
  };
  const lineLabels = weeks;
  const lineData = {
    labels: lineLabels,
    datasets: [
      {
        
        fill:true,
        label: 'Opened',
        data: dayOpen,
        borderColor: '#8C27A5',
        backgroundColor: 'rgba(140, 39, 165,0.5)',
        tension: 0.3
      },
      {
        fill: true,
        label: 'Closed',
        data: dayClose,
        borderColor: 'rgb(69, 203, 233)',
        backgroundColor: 'rgba(69, 203, 233,0.5)',
        tension: 0.3
      },
    ],
  };

  //bar chart
  var dat1 = show?(monthClosed&&monthClosed.slice(6, ))||"":(monthClosed&&monthClosed.slice(0, 6))||""
  var dat2 = show?(monthTotal&&monthTotal.slice(6, ))||"":(monthTotal&&monthTotal.slice(0, 6))||""
  // var dat1 = monthClosed&&monthClosed
  // var dat2 = monthTotal&&monthTotal
  const barData = {
    labels: show?months&&months.slice(6, ):months&&months.slice(0 ,6),
    // labels: months&&months,
    datasets: [
      {
        label: 'New Leads',
        backgroundColor: '#ff6258',
        data: dat2,
        borderRadius: {
          topRight: 7.5,
          topLeft: 7.5,
          // bottomRight: 10,
          // bottomLeft: 10
        },
        barPercentage: 1,
        categoryPercentage: 0.7,
        borderWidth: 0,
        borderSkipped: false,
      },
      {
        label: 'Closed',
        backgroundColor: '#5585ff',
        data: dat1,
        borderRadius: {
          topRight: 7.5,
          topLeft: 7.5,
          // bottomRight: 10,
          // bottomLeft: 10
        },
        borderWidth: 0,
        barPercentage: 1,
        categoryPercentage: 0.7,
        borderSkipped: false,
      }
    ],
  };

  const barOptions = {
    plugins: {
      datalabels:{display:false},
      legend: {
        display: true,
        position: 'bottom',
      },
    },
    scales: {
      x: {
        // stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        // stacked: true,
        // display:false,
        grid: {
          display: true,
          drawTicks: false //hide datatype lines
        },
        beginAtZero: true,
        ticks: {
          display: false //hide the datatype values
        }
      },
    },
  };

  const pieData = {
    labels: ["New Lead", "Open", "Walk In", "Rejected", "Closed", "Not Responded", "No Leads"],
    datasets: [{
      data: status&&
          [
            status['new'], 
            status['open'], 
            status['walk_in'],
            status['rejected'], 
            status['closed'], 
            status['unavailable'],
            status['new']==0&&status['open']==0&&status['closed']==0&&status['unavailable']==0&&status['walk_in']==0&&status['rejected']==0?1:0]
          // :[]
          ,
      backgroundColor: [
        'rgba(123, 6, 194,0.5)', 'rgba(0, 55, 195, 0.5)', 'rgba(0, 194, 89, 0.5)', '#CA380A', 'rgba(188, 0, 124, 0.5)', 'rgba(255, 199, 0, 0.5)','rgba(244, 244, 244, 0.5)'
      ]
    }]

  }
  //pie chart
  const pieOptions = {
    type: 'doughnut',
    // data: pieData,
    options: {
      plugins: {
        emptyDoughnut: {
          color: 'rgba(255, 128, 0, 0.5)',
          width: 2,
          radiusDecrease: 20
        }
      }
    },
    plugins: {
      datalabels:{display:false},
      legend: {
        display: false
      },
      tooltip: {
        // backgroundColor: '#fff',
        yAlign: {
          display: false,
        },
      }
    }
  }

  switch(auth.role){
    case 'Company':
      return <DashboardCompany
        {...{ lineData, lineOptions, barData, barOptions, pieData, pieOptions, cardData, show, setShow, sourceData, filter, setFilter, statusCount, sourceCount }}
      />
    case 'Admin':
      return <AdminDashboard
        {...{
          companyData,
          companyList,
          callPercentage
        }}
      />
    default:
      return <DashboardAgent
        {...{ lineData, lineOptions, barData, barOptions, pieData, pieOptions, cardData, show, setShow, sourceData, filter, setFilter, statusCount, sourceCount }}
      />
  }
}

export default Dashboard