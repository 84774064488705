import { useState } from "react";
import { FiDownload } from "react-icons/fi";
import ReactPlayer from "react-player";
import { REC_URL } from "../../../api/axios";
import "../CallLog.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import outgoing from "../../../assets/images/imgs/outgoing.svg";
import incoming from "../../../assets/images/imgs/incoming.svg";
import missed from "../../../assets/images/imgs/missed.svg";
import recent from "../../../assets/images/imgs/recent.svg";

const LogItem = ({ index, data, history }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getDate = (data) => {
    return new Date(data).toString().slice(4, 15);
  };
  const getTime = (data) => {
    return new Date(data).toLocaleTimeString();
  };
  const getDuration = (data) => {
    if (data > 3600) {
      let hours = Math.floor(data / 3600);
      let min = Math.floor((data % 3600) / 60);
      let sec = (data % 3600) % 60;
      let time = hours + "h " + min + "m " + sec + "s";
      return time;
    } else if (data > 60) {
      let min = Math.floor(data / 60);
      let sec = data % 60;
      let time = min + "m " + sec + "s";
      return time;
    } else {
      let time = data + "s";
      return time;
    }
  };
  const getStatus = (data) => {
    if (data == "CANCEL" || data == "Cancel" || data == "NOANSWER") {
      return "NO RESPONSE";
    }
    return data;
  };

  return (
    <div className="col-lg-12 p-0 m-0" style={{ height: "auto" }}>
      <div
        className="followup-card col-12 row p-3"
        style={{ height: "auto", borderRadius: "0px" }}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className="ps-3 pe-0 p-1 my-2 followup-card-title call">
          {index}
        </div>
        <div
          className={`col-1 col-2 overflow-hidden my-2 followup-card-title followup-data p-1 pe-4 ps-0 d-flex align-items-center gap-1 ${
            (data.call_status === "Cancel" ||
              data.call_status === "NOANSWER") &&
            data.call_type === "Incoming"
              ? "text-danger"
              : ""
          }`}
        >
          <div className="d-flex m-0 p-0 align-items-center gap-1">
            <img
              src={
                data.call_type === "Incoming" &&
                (data.call_status === "Cancel" ||
                  data.call_status === "NOANSWER")
                  ? missed
                  : data.call_type === "Incoming"
                  ? incoming
                  : outgoing
              }
              alt="something went wrong"
            />
            {data.staff_name}
          </div>
        </div>
        <div
          className={`col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden ${
            (data.call_status === "Cancel" ||
              data.call_status === "NOANSWER") &&
            data.call_type === "Incoming"
              ? "text-danger"
              : ""
          }`}
        >
          {data.client_number}
        </div>
        <div
          className={`col-1 col-2 overflow-hidden my-2 followup-card-title followup-data p-1 pe-5 ps-0 ${
            (data.call_status === "Cancel" ||
              data.call_status === "NOANSWER") &&
            data.call_type === "Incoming"
              ? "text-danger"
              : ""
          }`}
        >
          {data.staff_name}
        </div>
        <div
          className={`col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden ms-1 ${
            (data.call_status === "Cancel" ||
              data.call_status === "NOANSWER") &&
            data.call_type === "Incoming"
              ? "text-danger"
              : ""
          }`}
        >
          {getTime(data.start_time)}
        </div>
        <div
          className={`col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden ms-1 ${
            (data.call_status === "Cancel" ||
              data.call_status === "NOANSWER") &&
            data.call_type === "Incoming"
              ? "text-danger"
              : ""
          }`}
        >
          {getTime(data.end_time)}
        </div>
        <div
          className={`my-2 followup-card-title call followup-data p-1 px-2 overflow-hidden ps-0 ${
            (data.call_status === "Cancel" ||
              data.call_status === "NOANSWER") &&
            data.call_type === "Incoming"
              ? "text-danger"
              : ""
          }`}
        >
          {getDuration(data?.call_duration)}
        </div>
        <div
          className={`col-1 my-2 followup-card-title followup-data p-1 ps-0 pe-1 overflow-hidden ms-5 ${
            (data.call_status === "Cancel" ||
              data.call_status === "NOANSWER") &&
            data.call_type === "Incoming"
              ? "text-danger"
              : ""
          }`}
        >
          {getStatus(data?.call_status)}
        </div>
        {data?.call_duration ? (
          <div className="col-3 followup-card-title followup-data p-0 pe-2 overflow-hidden d-flex ps-2">
            <ReactPlayer
              url={REC_URL + data?.recording_url}
              height="2.5rem"
              playing={false}
              controls={true}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
            &nbsp;
            <a
              href={REC_URL + data?.recording_url}
              className="btn btn-success call"
            >
              <span>
                <FiDownload size="18px" />
              </span>
            </a>
          </div>
        ) : (
          <div className="col-3"></div>
        )}
        <div className="col" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <ExpandMoreIcon />
        </div>
        {isDropdownOpen && (
          <div className="accordion col-12 row p-3" style={{ height: "auto" }}>
            <Accordion className="w-100" expanded={true}>
              <AccordionDetails className="accordion-details">
                <h5 className="recent w-100 text-secondary pb-1 d-flex align-items-center gap-1">
                  <img src={recent} alt="" />
                  Recent
                </h5>
                <table className="table">
                  <thead>
                    <tr className="text-secondary" style={{ fontSize: "12px" }}>
                      <th></th>
                      <th>Agent Name</th>
                      <th>Start time</th>
                      <th>End Time</th>
                      <th>Time</th>
                      <th>Status</th>
                      <th>Recordings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.history?.length > 0 &&
                      data.history.map((item, index) => (
                        <tr
                          key={index}
                          className="text-secondary"
                          style={{ fontSize: "12px" }}
                        >
                          <td>
                            <img
                              src={
                                item.call_type === "Incoming" &&
                                item.call_status === "NORESPONSE"
                                  ? missed
                                  : item.call_type === "Incoming"
                                  ? incoming
                                  : outgoing
                              }
                              alt="something went wrong"
                            />
                          </td>
                          <td>{item.staff_name}</td>
                          <td>{dayjs(item.start_time).format("HH:MM:ss A")}</td>
                          <td>{dayjs(item.end_time).format("HH:MM:ss A")}</td>
                          <td>{dayjs(item.start_time).format("HH:MM:ss A")}</td>
                          <td>{item.call_status}</td>
                          <td className="">
                            <div
                              className="d-flex align-items-center"
                              style={{ width: "240px" }}
                            >
                              <ReactPlayer
                                url={REC_URL + item.recording_url}
                                height="2.5rem"
                                playing={false}
                                controls={true}
                                config={{
                                  file: {
                                    attributes: {
                                      controlsList: "nodownload",
                                    },
                                  },
                                }}
                              />
                              &nbsp;
                              <a
                                href={REC_URL + item.recording_url}
                                className="btn btn-success call"
                              >
                                <span>
                                  <FiDownload size="18px" />
                                </span>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
};

export default LogItem;
