import React, { useCallback, useEffect } from "react";
import cal from "../../../assets/images/icons/calander.png";
import calw from "../../../assets/images/icons/cal-white.png";
import { useState } from "react";
import { FaTrashAlt } from 'react-icons/fa'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MEDIA_URL } from '../../../api/axios'
import { Dropdown, Header, Image } from "semantic-ui-react";

export const TaskForm = (props) => {
  const [openDate, setOpenDate] = useState(false);
  const [dateCheck, setDateCheck] = useState(false);
  const [agentSelect, setAgentSelect] = useState([{}])
  let startDate = new Date();
  const {stafs,staff,data2,setEditId,
    setStaff,formId,agentId,setAgentId,
    handleSubmit,setNewT, addTask,newT,
    setAddTask,handleChange,taskData,index,
    setFormId,handleClose,user,editTask,
    setEditTask,viewTask,setViewTask,editId,
    confirmDelete,noTask,tEVC,setNoTask
  } = props;

  // console.log(taskData)
  useEffect(() => {
    if (stafs) {
      var r = stafs.filter((x) => {
        return x.fk_user?.id === agentId
      })
      // console.log(agentId)
      setStaff(...r)
    }
  }, [agentId])


  useEffect(() => {
    const val = stafs?.map((data, index) => ({
      key: index,
      text: data.fk_user.first_name,
      value: data.fk_user.id,
      content:
        <Header className="select-header" key={index}>
          <Image className="head-image" circular src={data.fk_user.image ? MEDIA_URL + data?.fk_user.image : user} />
          <Header.Content className="txt1">
            {data?.fk_user.first_name}
            <Header.Subheader className="txt2">
              {data?.fk_user.fk_role}
            </Header.Subheader>
          </Header.Content>
        </Header>
    }))
    setAgentSelect(val)
  }, stafs)

  useEffect(() => {
    checkDate();
  });

  const checkDate = (e) => {
    if (newT.last_date) {
      const reg = /^([12]\d{3}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01]))$/;
      if (!reg.test(newT.last_date))
        setDateCheck(true);
      else {
        setDateCheck(false);
      }
    }
  };

  const handleDate = (date) => {
    var day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    var month = date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()
    var year = date.getFullYear()
    var data = `${year}/${month}/${day}`
    setNewT((newT) => ({ ...newT, last_date: data }))
    setOpenDate(false)
  }

  const handleSelect = (e, { value }) => {
    setAgentId(value)
  }
  const handelIdSelect = (i, data) => {
    setEditId(data.id)
    setFormId(i)
  }
  
  return (
    <div className="col-12 m-0 row">
      <div
        className={
          (editTask && window.innerWidth > 768) ||
            (addTask && window.innerWidth > 768)
            ? "col-md-3 rounded-4 tbar-add"
            : "p-3 col-md-3 rounded-4 tbar"
        }
      >
        {addTask && (
          <div
            className={"text-light mt-md-0 mt-3 h6 " + (addTask && "pb-2 pt-5")}
          >
            Select Staff
          </div>
        )}
        <div className={`${addTask && 'task-list-container '} agent rounded-4`}>
          {addTask ? (
            stafs &&
            stafs?.map((data, i) => {
              return (
                <div
                  key={i}
                  onClick={() => setAgentId(data?.fk_user?.id)}
                  className={` task-top d-flex ps-1 py-1 mt-2 rounded-4  add mb-1 btn  ${agentId === data.fk_user && ' active'}`}>
                  <div className="d-flex flex-row">
                    <img
                      alt=""
                      className="rounded-circle"
                      width={60}
                      height={60}
                      src={data?.fk_user?.image ? MEDIA_URL + data.fk_user?.image : user}
                    />
                  </div>
                  <div className="ps-3 ">
                    <div className="cardName pt-3 pe-5 d-flex">{data.fk_user.first_name}</div>
                    <div className="cardName2 text-start">{data.fk_user.fk_role}</div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className={
                "task-top d-flex ps-1 py-1 mt-2 rounded-4" +
                (addTask ? " add mb-5 btn" : "")
              }
            >
              <div className="d-flex flex-row">
                <img alt="" className="rounded-circle" width={60} src={data2?.fk_user.image !== null ? `${MEDIA_URL + data2.fk_user.image}` : user} />
              </div>
              <div className="ps-3 ">
                <div className="cardName pt-3 pe-5 d-flex">{data2 && data2?.fk_user.first_name}</div>
                <div className="cardName2 text-start">{data2 && data2?.fk_user.fk_role}</div>
              </div>
            </div>
          )}
        </div>
        {(viewTask || editTask) && taskData && (
          <div>
            <div className="pt-5 text-light">Task List</div>
            <div className="d-flex flex-column pt-3 mb-5 task-list-container">
              {taskData &&
                taskData.map((data, i) => {
                  return (
                    (editTask&&data.task_status!=="completed"||viewTask)&&<div
                      key={i}
                      // onLoad={()=>setFormId(0)}
                      onClick={() => handelIdSelect(i, data)}
                      className={`${formId === i && 'active'} f-task btn rounded-4 text-center mb-3 p-3`}
                    >
                      {data["task"]}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
      {/*----------------------------------------------------------------*/}
      <div
        className={
          (editTask && window.innerWidth > 768) ||
            (addTask && window.innerWidth > 768)
            ? "col-9 p-5 task-form-add"
            : "col-12 p-5 col-md-9 "
        }
      >
        {(editTask && taskData[formId]?.task_status !== "completed") && 
        <FaTrashAlt className="h4 text-danger float-end" onClick={confirmDelete} />}
        <div className="fw-bolder mt-4">
          {viewTask ? "Task Details" :editTask?"Edit Tasks" : "Add Task"}
        </div>
        <form className="col-12 d-flex flex-wrap mt-5 pt-4" onSubmit={handleSubmit}>
          <div
            className={
              "tForm col-12 " +
              (editTask || addTask ? "col-md-12" : "col-md-4 me-5")
            }
          >
            <div className="tform-text">
              {viewTask ? "Task Name" : "To Assign"}
            </div>
            {viewTask && <input
              disabled={true}
              required={addTask ? true : false}
              type="text"
              value={taskData && taskData[formId]["task"] ? taskData[formId]["task"] : "Select staff"}
              onChange={handleChange}
              className="rounded-4 bg-muted txt"
            />}
            {addTask &&
              <div className="tForm col-md-4 pe-2 pe-md-0 col-9">
                <input
                  required={addTask ? true : false}
                  disabled
                  placeholder={staff?.fk_user?.first_name ? staff?.fk_user.first_name : "Select Staff"}
                  className="txt bg-muted rounded-4" /></div>}
            {editTask && <div className="tForm col-md-4 col-12">
              <Dropdown
                selection
                scrolling
                className="rounded-4 col-md-4 col-12 border bg-muted mt-1 select"
                search
                placeholder={"Select Staff"}
                onChange={handleSelect}
                options={agentSelect}
              ></Dropdown>
            </div>}
          </div>
          {viewTask && (
            <div className="tForm mt-5 ms-4 mt-md-0 col-md-5">
              <div className="tform-text">Status</div>
              <input
                required={addTask ? true : false}
                disabled={viewTask}
                type="text"
                className="rounded-4 bg-muted txt text-2"
                placeholder={viewTask ? taskData && taskData[formId]["task_status"] : ""}
              />
            </div>
          )}
          <div className="tForm mt-5 col-md-4 me-md-5">
            <div className="tform-text">
              {viewTask ? "Completed Date" : "Task Name"}
            </div>
            <div className="tForm m-0 p-0">
              <input
                required={addTask ? true : false}
                disabled={viewTask}
                type="text"
                name={"name"}
                className="rounded-4 txt"
                value={newT.task}
                onChange={handleChange}
                placeholder={
                  (taskData && editTask)
                    ? taskData[formId]["task"]
                    :( viewTask )? (taskData[formId]["updated_at"] &&  taskData[formId]["task_status"] === "completed" )? new Date(taskData[formId]["updated_at"]).toLocaleDateString() : "Not completed"
                      : "Enter task name"
                }
              />
              {viewTask && (
                <img
                  className="tForm-img mx-3 mx-md-0"
                  alt=""
                  src={cal}
                  width={22}
                />
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="tForm mt-5 col-md-12 ms-md-4">
              <div className="tform-text">Dead Line</div>
              <div className="tForm mt-md-1">
                {
                  <input
                    required={addTask ? true : false}
                    disabled={viewTask}
                    name={"last_date"}
                    type="text"
                    value={newT.last_date}
                    onChange={handleChange}
                    className="rounded-4 m-0 txt"
                    placeholder={
                      (taskData && (editTask || viewTask))
                        ? taskData[formId]["last_date"]
                        : "YY/MM/DD"
                    }
                  />
                }
                <div onClick={() => setOpenDate(!openDate)} className="img-cal">
                  <img alt="" src={calw} width={22} />
                </div>
              </div>
            </div>
            {(viewTask === false && openDate) && <div className="tForm-cal">
              <DatePicker
                height='40px'
                minDate={new Date()}
                calendarClassName="tForm-cal1"
                dateFormat="yyyy/MM/dd"
                inline
                disabled={viewTask}
                selected={startDate}
                onChange={(date) => { handleDate(date) }}
              />
            </div>}
            {dateCheck && (
              <div className="txt3 ps-5 text-danger">
                {"wrong date - Date format is YY/MM/DD"}
              </div>
            )}
          </div>
          <div className="tForm col-12 mt-5 col-md-12">
            <div className="tform-text">
              {viewTask ? "Remark" : "Task Description"}
            </div>
            <div className="tForm pe-md-5 mt-1">
              <textarea
                required={addTask ? true : false}
                name={"desc"}
                value={newT.desc}
                onChange={handleChange}
                disabled={viewTask}
                className="rounded-4 pb-5 m-0 txt txt3"
                placeholder={
                  (editTask && taskData) ? taskData[formId]["task_descriptions"]
                    : viewTask ? taskData[formId]["remark"] || "No remark here"
                      : "Enter Description"
                }
              />
            </div>
          </div>
          <div className="text-end col-12 pe-md-5 pt-4">
            <div onClick={handleClose} className="btn form-btn rounded-4 px-5">
              cancel
            </div>
            {(addTask || editTask) && (
              <input type="submit"
                value={"submit"}
                className="btn form-btn2 rounded-4 px-5 ms-md-3 mt-2 mt-md-0"
              />

            )}
          </div>
        </form>
      </div>
    </div>
  );
};
