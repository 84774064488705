import "./Task.css";
import React, { useState } from "react";
import priority from "../../assets/images/icons/Prioritize.png";
import useAuth from "../../hooks/useAuth";
import { TaskCard } from "./components/TaskCard";
import user from "../../assets/images/imgs/user.png";
import useCompanyServices from "../../services/companyServices";
import useAgentServices from "../../services/agentServices";
import useStaffServices from "../../services/staffServices";
import { Nav, Tab } from "react-bootstrap";
import { useEffect } from "react";
import AgentTaskList from "./components/AgentTaskList";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { TaskForm } from "./components/TaskForm";
import useBaseServices from "../../services/baseServices";

export const Task = () => {
  // task card buttons
  const [addTask, setAddTask] = useState(false);
  const [viewTask, setViewTask] = useState(false);
  const [editTask, setEditTask] = useState(false);

  // data states
  const [tasks, setTasks] = useState(); // all tasks of an agent | staff
  const [stafs, setStafs] = useState([]); // all staff | agent 's
  const [staff, setStaff] = useState([]); // filetered stafs 
  const [taskData , setTaskData] = useState() // tasks of single
  const [data2, setData2] = useState([]); // task list

  // condition states
  const [agentId, setAgentId] = useState(0);
  const [editId, setEditId] = useState();
  const [formId, setFormId] = useState(1);
  const [key, setKey] = useState("tab1");
  const [noTask, setNoTask] = useState(false);
  const [tEVC, setTEVC] = useState(false); // task edit view check

  const [newT, setNewT] = useState({
    name: "",
    desc: "",
    last_date: "",
  });
  
  // base services
  const { deleteTaskCreator } = useBaseServices();

  // staff services
  const {
    // getStaffTaskList,
  } = useStaffServices()

  const {getStaffList,
  postTask,getCreatedTaskList,
  getStaffTaskList,putTaskCreator,
  putTaskUser,getTaskUser,
  getUserTaskList,
  } = useBaseServices()

  const { auth } = useAuth();


  // useEffect to call all task
  useEffect(() => {
    if(auth.role === "Agent"){
      setKey("tab2")
      setTaskData()}
    getTask();
  }, [viewTask,addTask,key]);
  //get all task function
  const getTask = async () => {
    try {
      var response1;
      var response2;
      if (auth.role==='Company') {
        response2 = await getStaffList();
      } else if (auth.role !== "Company" && key === "tab1"){
        var data = {
          user_id:auth.staffId
        }
        response2 = await getStaffList(data)
      }else if (key === "tab2"){
        response1 = await getUserTaskList()
      }
      if(response1?.success && response1.data.tasks.length>0){
        setTasks(response1?.data.tasks)
      }
      if (response2?.success) {
        if (auth.role === "Sub Admin") {
          let a = [];
          // console.log(response2.data)
          a = response2?.data
          // response2?.data.map((x) => {
            // let g = x.fkStaff
            // g.task = x.fkStaff.report.total_task 
            // g.completed = x.fkStaff.report.total_completed_task
            // g.pending = x.fkStaff.report.total_not_completed_task
            // a.push(g);
          // });
          setStafs(a);
        } else if(auth.role==='Company'){
          let a = [];
          a = response2?.data
          // response2?.data.map((x) => {
            // let g = x
            // g.task = x.report.total_task 
            // g.completed = x.report.total_completed_task
            // g.pending = x.report.total_not_completed_task
            // a.push(g);
          // });
          setStafs(a);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // function to accept input values
  const handleChange = (e) => {
    setNewT((newT) => ({ ...newT, [e.target.name]: e.target.value }));
  };

  // confirm alert
  const confirmDelete =async (e) =>{
    Swal.fire({
      title: 'Do you want to delete the Task?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Delete',
      denyButtonText: `Don't delete`,
    }).then(async(result) => {
      if (result.isConfirmed) {
        await handleDelete(e)
      } else if (result.isDenied) {
        Swal.fire('task not deleted', '', 'info')
      }
    })
  }

  const handleDelete = async (e) =>{
    e.preventDefault()
    try{
      if(taskData[formId]?.task_statu === 'completed'){
        Swal.fire('Cannot delete a completed task')
        return 0
      }
      let res = await deleteTaskCreator(editId)
      setFormId(0)
      if(res?.success || res?.message === "delete Successfuly"){
        let res2 = await getStaffTaskList(agentId)
        if(res2?.data?.length==0 && staff.total_completed_task !== staff.total_task){
          handleClose()
          getTask()
        }else{
          handleClose()
          setTaskData(res2?.data)
          setEditId(res2?.data[0]?.id)
          getTask()
        }
        Swal.fire('Deleted!', '', 'success')
        return 0
      }else{
        Swal.fire(res.message)
      }
    }catch(err){
      Swal.fire(err.response.data.message, '', 'error')
    }
  }

  // call when submit is clicked
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!agentId){
      Swal.fire('Please Select agent','','error')
      return 0
    }
    try {
      var res;
      const data = JSON.stringify({
        task: newT.name,
        task_descriptions: newT.desc,
        user_id: agentId,
        last_date: newT.last_date,
        task_status: 'not_completed'
      });
      if(addTask){
          res = await postTask(data);
      }else if(editTask){
          res = await putTaskCreator(editId,data);
      }
      getTask();
      handleClose()
      if(res?.success){
        Swal.fire("", res?.message, "success");
      }
      else{
        Swal.fire("", res?.message, "error");
      }
    } catch (err) {
      let error = err?.response?.data?.message
      if(error == 'user doesnot exist') error = "Please select staff"
      Swal.fire("", error||"Something went wrong", "error");
    }
  };
  

  // clear all values when closed
  const handleClose = () => {
    setViewTask(false);
    setAddTask(false);
    setEditTask(false);
    setFormId(0);
    setAgentId()
    setTEVC(false)
    setNoTask(false)
    setNewT({
      ...newT, name: "",
      desc: "",last_date: "",
      fk_user_to_assign:"",});
    };



  return (
    <div className="ms-md-0 ms-4">
      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
        <div className="row">
          <div id="staff-header-btn border" className="row task-tab-main">
            {/* <div className="row border"> */}
            {auth.role === "Sub Admin" && (
              <Nav variant="pills" className=" ps-4 task-tab">
                <Nav.Item className="pt-2 px-md-3">
                  <Nav.Link
                    className="staff-cal text-end ui circular button py-3  px-md-5 "
                    eventKey="tab1"
                  >
                    Staff
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="pt-2 px-md-3">
                  <Nav.Link
                    className="staff-cal text-end ui circular button py-3  px-md-5 "
                    eventKey="tab2"
                  >
                    Tasks
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            )}
            {(auth.role !== "Agent" && key ==="tab1" && auth.isSwitch=='false')&&<div
              onClick={() => setAddTask(true)}
              style={{ backgroundColor: "#3E66CD" }}
              className="staff-cal ui add-task circular button d-flex text-light"
            >
              <img src={priority} className="me-3 ms-1" width={20} />
              <div className="task-txt mt-1">Add Task</div>
            </div>}
          </div>
          <div className="row mt-5">
            <Tab.Content>
              <Tab.Pane eventKey="tab1" className="">
                <div className="row mt-2 me-3 me-md-0">
                  {stafs &&
                    stafs?.map((StaffData, index) => (
                      // <div key={index}>
                      <div key={index} className='col-lg-4 col-md-6 my-3'>
                      <TaskCard
                        {...{
                          getUserTaskList,setViewTask,
                          getStaffTaskList,editId,
                          setData2,setEditId,setTEVC,
                          key,setNoTask,auth,setTaskData,
                          setAgentId,setNewT,newT,StaffData,
                          setFormId,user,setEditTask,
                        }}
                      />
                   </div>
                   ))}
                </div>
                <Modal
              show={viewTask || addTask || editTask || noTask}
              onHide={handleClose}
              centered={true}
              size={noTask?"lg":"xl"}
              contentClassName="staff-modal"
            >
              <Modal.Body className="p-0 m-0">
               {(editTask||addTask||viewTask)&&
               <TaskForm
                 {...{stafs,staff,data2,setEditId,
                    setStaff,formId,agentId,setAgentId,
                    handleSubmit,setNewT, addTask,newT,
                    setAddTask,handleChange,taskData,
                    setFormId,handleClose,user,editTask,
                    setEditTask,viewTask,setViewTask,
                    confirmDelete,noTask,tEVC,setNoTask
                  }}
                /> }
              {noTask&&<div className="text-center"><div className="h4 text-center py-5 my-5">{`No task ${tEVC?"to edit ":"added "} for this Staff!`}</div>
              <div onClick={()=>{handleClose()}} className="btn mb-5 form-btn2 rounded-4 px-5 ms-3">Cancel</div></div>}
              </Modal.Body>
            </Modal>
               
              </Tab.Pane>
              <Tab.Pane eventKey="tab2" className="">
                <div className="row mt-3">
                <AgentTaskList refresh={getTask} data={tasks} auth={auth} />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
};
