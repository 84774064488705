import { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Popup, Dropdown, Search, Icon } from 'semantic-ui-react';
import useAuth from '../../../hooks/useAuth';
import { MEDIA_URL } from '../../../api/axios'
import user from '../../../assets/images/imgs/user.png'
import useBaseServices from '../../../services/baseServices';
import useCompanyServices from '../../../services/companyServices';
import useAgentServices from '../../../services/agentServices';
import { DropdownButton } from 'react-bootstrap';
import { DatePicker } from '@mui/x-date-pickers';

const StaffReport = () => {
    const [leadList, setLeadList] = useState()
    const [staffList, setStaffList] = useState()
    const [name, setName] = useState()
    const [image, setImage] = useState()
    const [mobile, setMobile] = useState()
    const [post, setPost] = useState()
    const [months, setMonths] = useState()
    const [closed, setClosed] = useState()
    const [open, setOpen] = useState()
    const [unavailable, setUnavailable] = useState()
    const [paused, setPaused] = useState()
    const [walkIn, setWalkIn] = useState()
    const [rejected, setRejected] = useState()
    const [total, setTotal] = useState()
    const [show, setShow] = useState(1)

    const [filter, setFilter] = useState("Filters")

    const { auth } = useAuth()

    const { getStaffMonthlyReport, getStaffLeadReport, getStaffList } = useBaseServices()
    // const { getCompanyStaffList } = useCompanyServices()
    // const { getAgentStaffList } = useAgentServices()


    useEffect(() => {
        getStaffLists()
    }, []);

    const getStaffLists = async () => {
        var data = {
            user_id: auth.staffId
        }
        const response = await getStaffList(data)
            console.log(response?.data)
            setStaffList(response?.data)
            var data = response.data[0]
            setName(data.fk_user.first_name)
            setImage(data.fk_user.image?[MEDIA_URL] + data.fk_user.image:user)
            setMobile(data.fk_user.mobile)
            setPost(data.fk_user.fk_role)
            getLeadList(data.fk_user.id)
            getLeadChart(data.fk_user.id)
    }
    // console.log(leadChart)
    const getLeadList = async (userId) => {
        const response = await getStaffLeadReport(userId)
        // console.log(response?.data.leads)
        setLeadList(response.data)
    }
    const getLeadChart = async (userId) => {
        const response = await getStaffMonthlyReport(userId)
        // console.log(response?.data)
        // setLeadChart(response.data)
        var data = response.data
        setMonths(data['months'])
        setClosed(data['closed'])
        setOpen(data['open'])
        setRejected(data['rejected'])
        setUnavailable(data['unavailable'])
        setWalkIn(data['walk_in'])
        setPaused(data['paused'])
        setTotal(data['total'])
    }

    let arr1 = [closed,open,rejected,unavailable,paused,total]

    const comp = (arr) =>{
        let r = []
        r = arr.flat()
         r = r.sort((a,b)=>a-b)
         return r[r.length-1]
    }

    let big = comp(arr1)

    // const handleSearch = () => {

    // }

    //dropdown

    // const dropDownItems = [
    //     { key: 1, text: 'All', value: 1 },
    //     { key: 2, text: 'Closed ', value: 2 },
    //     { key: 3, text: 'Rejected', value: 3 },
    //     { key: 4, text: 'Unavailable', value: 4 },
    //     { key: 5, text: 'Paused', value: 5 },
    //     { key: 6, text: 'Open', value: 6 },
    // ]


    //chart
    const shows = (val) =>{
        if(window.innerWidth>768){
          return show===2?val&&val.slice(0,6):val&&val.slice(6,)
        }
        return show===1?val&&val.slice(9, ):show===2?val&&val.slice(0, 3):show===3?val&&val.slice(3, 6):val&&val.slice(6, 9)
      }
     
      
      const barData = {
        labels:shows(months),
        datasets: [
          {
            label: 'Closed',
            backgroundColor: '#35D40D',
            data: shows(closed),
            borderRadius: {
              topRight: 10,
              topLeft: 10,},
            barPercentage: 1.26,
            borderColor: "rgba(0,0,0,0)",
            borderWidth: 5,
          maxBarThickness: 40,
          },
          {
            label: 'Rejected',
            backgroundColor: '#D31C1C',
            data: shows(rejected),
            borderRadius: {
              topRight: 10,
              topLeft: 10,},
            barPercentage: 1.26,
            borderColor: "rgba(0,0,0,0)",
            borderWidth: 5,
          maxBarThickness: 40,
          },
          {
            label: 'Unavailable',
            backgroundColor: '#DEC910',
            data: shows(unavailable),
            borderRadius: {
              topRight: 10,
              topLeft: 10,},
            barPercentage: 1.26,
            borderColor: "rgba(0,0,0,0)",
            borderWidth: 5,
          maxBarThickness: 40,
          },
          {
            label: 'Paused',
            backgroundColor: '#9745FF',
            data: shows(paused),
            borderRadius: {
              topRight: 10,
              topLeft: 10,},
            barPercentage: 1.26,
            borderColor: "rgba(0,0,0,0)",
            borderWidth: 5,
            maxBarThickness: 40,
          },
          {
            label: 'Walk In',
            backgroundColor: '#ff8500',
            data: shows(walkIn),
            borderRadius: {
              topRight: 10,
              topLeft: 10,},
            barPercentage: 1.26,
            borderColor: "rgba(0,0,0,0)",
            borderWidth: 5,
            maxBarThickness: 40,
          },
          {
            label: 'Opened',
            backgroundColor: '#25B4D3',
            data: shows(open),
            borderRadius: {
              topRight: 10,
              topLeft: 10,},
            barPercentage: 1.26,
            borderColor: "rgba(0,0,0,0)",
            borderWidth: 5,
            maxBarThickness: 40,
          },
          {
            label: 'New',
            backgroundColor: '#000000',
            data: shows(total),
            borderRadius: {
              topRight: 10,
              topLeft: 10,},
            barPercentage: 1.26,
            borderColor: "rgba(0,0,0,0)",
            borderWidth: 5,
            maxBarThickness: 40,
          },
        ]
      }
    
    
    const barOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: true,
            color: "black",
            formatter: Math.round,
            anchor: "end",
            offset: -15,
            align: "start"
          },
          legend: {
            position: 'top',
            labels:{
              boxWidth:15,
              padding:20,
              useBorderRadius:true,
              borderRadius:2,
              font: {
                size:15,
              },
            },
            title:{
              display:false,
              font:{
                size:78
              }
            },
          }, 
        },
        scales:{
         x:{
          grid:{drawborder:false,display:true,},
        },
        y:{
            grid:{display:true,drawTicks:false},
            max: big+0.5,
            ticks:{display:false}
        }
         },
        }

      const incShow =  (val) =>{
        if(val<4){
        val = val+1}
      setShow(val)
    //   console.log(show)
    }
    
    const decShow =  (val) =>{
      if(val>1){
      val = val-1}
      setShow(val)
    //   console.log(show)
    }
    return (
        <div className=''>
            <div className="row">
                {window.innerWidth <= 768 &&
                    <div className="col-12">
                        <div className="row mb-3 pe-0">
                            <div className={" col-5"}>
                                <div className=" report-staff-card selected py-4 px-2">
                                    <div className=" my-2 px-0 d-flex justify-content-center">
                                        <img src={image || user} className="report-staff-img" alt="" />
                                    </div>
                                    <div className=" my-3 px-4">
                                        <div className={"report-staff-card-title mb-2"}>{name}</div>
                                        <div className={"report-staff-card-position mb-1"}>{post}</div>
                                        <div className={"report-staff-card-phone"}>{mobile}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-7">
                                <div className={"report-staff-card default p-3 py-4 "}>
                                    <div className="report-staff-card-head-title h4 mb-3">
                                        Lead Team Members
                                    </div>
                                    <div className="scrollable-agent-list">
                                        {staffList && staffList.map(
                                            (data, index) => {
                                                console.log(data)
                                                return (
                                                    <div key={index} className="row my-2 mouse-pointer" onClick={() => {setImage(data.fk_user.image ? MEDIA_URL + data.fk_user.image : user); setName(data.fk_user.first_name); setMobile(data.fk_user.mobile); setPost(data.fk_user.fk_role); getLeadList(data.fk_user.id); getLeadChart(data.fk_user.id) }}>
                                                        <div className="col-4 report-staff-card-img">
                                                            <img src={data.fk_user.image ? MEDIA_URL + data.fk_user.image : user } alt="" />
                                                        </div>
                                                        <div className="col-8 my-auto">
                                                            <div className={"report-staff-card-title mb-1"}>{data.fk_user.first_name}</div>
                                                            <div className={"report-staff-card-position mb-1"}>{data.fk_user.post}</div>

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                <div className="col-12 col-md-9 ps-0">
                    <div className="row mb-3">
                        <div className="report-pane-staff p-4">
                            <div className="row mb-3">
                                <div className="col-3 report-staff-chart-title">
                                    Monthly Leads
                                </div>
                            </div>
                            <div className="row d-flex flex-row align-items-center">
                            <div className="col-1 align-items-end justify-content-end h-100 pt-5 mt-5 d-flex" onClick={()=>decShow(show)}>
                                <Icon name="angle left" size="big" hidden={show===1}/>
                            </div>
                            <div className="col-10 mx-auto" style={{height:350,}}>
                                <Bar data={barData} options={barOptions} />
                            </div>
                            <div className="col-1 align-items-center h-100 pt-5 mt-5 pe-4" onClick={()=>incShow(show)}>
                                <Icon name="angle right" size="big" hidden={window.innerWidth>768?show===2:show===4}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="report-pane-staff p-4">
                            <div className="row p-3">
                                <div className="report-pane-table p-3 ">
                                    <div className="table-responsive">
                                        <table className="table table-striped me-5">
                                            <thead>
                                                <tr>
                                                    <th>SL.</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                    <th>Interested</th>
                                                    <th>Followup</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {leadList && leadList.leads.map(
                                                    (data, index) => {
                                                        return (
                                                            // data.lead.status != 'New'?
                                                            <tr className='status-container' key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{data.name}</td>
                                                                <td>{data.email}</td>
                                                                <td>{data.mobile}</td>
                                                                <td>{data.interest}</td>
                                                                <td>{data.follow_up}</td>
                                                                <td><div className={' ' + data.status}>{data.status}</div></td>
                                                            </tr>
                                                            // :<></>
                                                        )
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 768 && <div className="col-3">
                    <div className="row mb-3 ms-3 pe-0">
                        <div className={"report-staff-card selected row p-4 px-4"}>
                            <div className="col-4 my-2 px-0">
                                <img src={image || user} className="report-staff-img" alt="" />
                            </div>
                            <div className="col-8 my-3 px-4">
                                <div className={"report-staff-card-title mb-2"}>{name}</div>
                                <div className={"report-staff-card-position mb-1"}>{post}</div>
                                <div className={"report-staff-card-phone"}>{mobile}</div>
                            </div>
                        </div>
                        <div className={"report-staff-card default col-12 row p-3 py-4 "}>

                            <div className="report-staff-card-head-title row h4 mb-3">
                                Lead Team Members
                            </div>
                            <div className=" my-2">
                                {staffList && staffList.map(
                                    (data, index) => {
                                        return (
                                            <div key={index} className="row my-2 mouse-pointer" onClick={() => {setImage(data.fk_user.image ? MEDIA_URL + data.fk_user.image : user); setName(data.fk_user.first_name); setMobile(data.fk_user.mobile); setPost(data.fk_user.fk_role); getLeadList(data.fk_user.id); getLeadChart(data.fk_user.id) }}>
                                                <div className="col-md-4 report-staff-card-img">
                                                    <img src={data.fk_user.image ? MEDIA_URL + data.fk_user.image : user} alt="" />
                                                </div>
                                                <div className="col-md-8 my-auto">
                                                    <div className={"report-staff-card-title mb-1"}>{data.fk_user.first_name}</div>
                                                    <div className={"report-staff-card-position mb-1"}>{data.fk_user.fk_role}</div>

                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default StaffReport