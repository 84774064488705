import React from "react";
import { useState, useEffect } from "react";
import { Accordion, Form, InputGroup, Nav, Tab } from "react-bootstrap";
import { BiCopy } from "react-icons/bi";
import Swal from "sweetalert2";
import useAuth from "../../../hooks/useAuth";
import useCompanyServices from "../../../services/companyServices";
import useBaseServices from "../../../services/baseServices";
import useAdminServices from "../../../services/adminServices";

const VoxbayData = () => {
  const [key1, setKey1] = useState("tab1");
  const [accessToken, setAccessToken] = useState("");
  const { auth, setAuth } = useAuth();

  const { getCompanyProfile, putCompanyProfile, changeCompanyPrivacy } =
    useCompanyServices();
  const {
    getStaffProfile,
    getCompanyEmailConfig,
    postCompanyEmailConfig,
    putStaffProfile,
    putPasswordChange,
  } = useBaseServices();

  const {
    postAdminEmailConfig,
    getAdminEmailConfig,
    getAdminProfile,
    putAdminProfile,
  } = useAdminServices();

  useEffect(() => {
    getProfile();
  }, []);

  const WebForm = [
    { label: "access_token", data: accessToken },
    { label: "name", data: "<Name>" },
    { label: "email", data: "<Email Addres>" },
    { label: "mobile", data: "<Phone Number>" },
    { label: "interest", data: "<Interest>" },
    { label: "website_name", data: "<Website Name>" },
  ];

  const getProfile = async () => {
    try {
      var image;
      var response;
      if (auth.role === "Company") {
        response = await getCompanyProfile();
        setAccessToken(response.data.access_token);
      }
    } catch (err) {}
  };

  const handleCopy = (data) => {
    navigator?.clipboard?.writeText(data);
    Swal.fire({
      text: `Copied ${data.length > 25 ? "" : `'${data}'`} !!`,
      showConfirmButton: false,
      timer: 1000,
      position: "bottom-end",
      toast: true,
      background: "#000",
      color: "#FFF",
      width: "25rem",
    });
  };
  return (
    <div className="company-list-container">
      <div className="company-list-card">
        <div className="company-list-header row mx-0">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Website Integration</Accordion.Header>
              <Accordion.Body>
                <div className="company-list-header row mx-0">
                  <Form.Group className="col-2">
                    <Form.Label className="h5 ffw-bold">Method</Form.Label>
                    <Form.Control value={"POST"} disabled />
                  </Form.Group>
                  <Form.Group className="col-10">
                    <Form.Label className="h5 ffw-bold">URL</Form.Label>
                    <InputGroup>
                      <Form.Control
                        value={
                          "https://crm-beta-api.vozlead.in/api/v2/lead/website/"
                        }
                        disabled
                        aria-describedby="request_url"
                      />
                      <InputGroup.Text
                        id="request_url"
                        className="mouse-pointer"
                        onClick={() =>
                          handleCopy(
                            "https://crm-beta-api.vozlead.in/api/v2/lead/website/"
                          )
                        }
                      >
                        <BiCopy size={"1.1rem"} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </div>
                <Tab.Container activeKey={key1} onSelect={(k) => setKey1(k)}>
                  <div className="row mt-3">
                    {/* <div className="col-12">
                                            <Nav variant="tabs" className="flex-md-row flex-column">
                                                <Nav.Item className='pt-2 pe-md-1'>
                                                    <Nav.Link className='py-2  px-md-4 ' eventKey="tab1">Start</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div> */}
                    <Tab.Content>
                      <Tab.Pane eventKey="tab1" className="">
                        <div className="followup-container">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th style={{ width: "4.5rem" }}>Sl No.</th>
                                <th>Key</th>
                                <th>Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {WebForm &&
                                WebForm.map((item, index) => {
                                  return (
                                    <>
                                      {item.label === "access_token" ? (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td className="">
                                            <div className="row mx-0 px-0 py-0 align-items-center">
                                              <div className="col-11 px-1">
                                                {item.label}
                                              </div>
                                              <div className="col-1 px-0">
                                                <div
                                                  className="btn px-1 py-1"
                                                  onClick={() =>
                                                    handleCopy(item.label)
                                                  }
                                                >
                                                  <BiCopy size={"1.3rem"} />
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="">
                                            <div className="row mx-0 px-0 py-0 align-items-center">
                                              <div className="col-11 px-1">
                                                {item.data}
                                              </div>
                                              <div className="col-1 px-0 text-end">
                                                <div
                                                  className="btn px-1 py-1"
                                                  onClick={() =>
                                                    handleCopy(item.data)
                                                  }
                                                >
                                                  <BiCopy size={"1.3rem"} />
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ) : (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td className="">
                                            <div className="row mx-0 px-0 py-0 align-items-center">
                                              <div className="col-11 px-1">
                                                {item.label}
                                              </div>
                                              <div className="col-1 px-0">
                                                <div
                                                  className="btn px-1 py-1"
                                                  onClick={() =>
                                                    handleCopy(item.label)
                                                  }
                                                >
                                                  <BiCopy size={"1.3rem"} />
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="">
                                            <div className="row mx-0 px-0 py-0 align-items-center">
                                              <div className="col-12 ps-1">
                                                {item.data}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default VoxbayData;
