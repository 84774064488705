import user from "../../../assets/images/logos/user.png"
import call_image from "../../../assets/images/logos/call.png"
import graph from "../../../assets/images/logos/graph.png"
import task_image from "../../../assets/images/logos/task.png"
import DashboardCard from "./DashboardCard"
import ProgressBar from "@ramonak/react-progress-bar";
import useAuth from "../../../hooks/useAuth"
import LeadUplodForm from "./LeadUplodForm"
import { Icon, Ref } from "semantic-ui-react"
import { Modal } from "react-bootstrap"
import { Bar, Line, PolarArea, Doughnut } from "react-chartjs-2"
import { Filler } from "chart.js";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { useRef } from "react"
import { useState } from "react"
import { useEffect } from "react"
import PopupCard from "../../notification/components/PopupCard"
import useLeadServices from "../../../services/leadServices"
import { popoverClasses } from "@mui/material"
import DashboardLeadCard from "./DashboardLeadCard"
import DashboardCallCard from "./DashboardCallCard"
import DashboardFilter from "./DashboardFilter"
import DashboardSourceFilter from "./DashboardSourceFilter"




const DashboardCompany = (props) => {
  const [popupData, setPopupData] = useState()
  const [popupList, setPopupList] = useState()

  const { getFollowUpReminderList } = useLeadServices()

  ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend, Filler);

  const { lineData, lineOptions, barData, barOptions, pieData, pieOptions, cardData, show, setShow, sourceData, filter, setFilter, statusCount, sourceCount } = props
  const { auth } = useAuth()
  useEffect(() => {
    getPopupData();
  }, [])

  const getPopupData = async () => {
    try {
      const response = await getFollowUpReminderList()
      if (response?.success) {
        var temp = response?.data
        setPopupData(temp.slice(3, temp.length + 1))
        setPopupList(temp.slice(0, 3))
      }
      else {
        // console.log(response?.message)
      }
    } catch (err){
      console.log(err?.response)
    }

  }

  const handlePopup = () => {
    // console.log(popupData)
    // if (popupData.length>0){
    //   setPopupList(popupData.splice(0, 3))
    // }
  }
  const filterOptions = ['Month', 'Today', 'Yesterday', 'Week', '90 Days']
  return (
    <div className="ms-md-0 ms-4">
      <DashboardFilter
        {...{
          filter,
          setFilter
        }}
      />
      <div>
        {popupList && <PopupCard data={popupList} getNewPopup={handlePopup} />}
      </div>
      <div className="row mt-3">
        <DashboardLeadCard classes="company" img={graph} title="Lead" count1={cardData && cardData['total_leads']} count2={cardData && cardData['leads_completed']} />
        <DashboardLeadCard classes="company" img={user} title="FollowUp" count1={cardData && cardData['total_followups']} count2={cardData && cardData['followups_completed']} />
        <DashboardCallCard classes="company" img={call_image} title="Calls" count1={cardData && cardData['incoming_call_count']} count2={cardData && cardData['outgoing_call_count']} />
        <DashboardLeadCard classes="company" img={task_image} title="Task" count1={cardData && cardData['task_not_completed']} count2={cardData && cardData['task_completed']} />

      </div>
      <div className="row mt-1">
        <div className=" col-md-6 p-4 ps-0">
          <div className="chart-card p-3">
            <div className="row mb-3 ">
              <div className="h5 fw-bolder">Daily Lead</div>
            </div>
            <div className="row">
              <div className="col-md-2 my-auto">
                <div className="my-auto text-center">
                  <div className="dashboard-card-count company">
                    {cardData && cardData['total_leads']}
                  </div>
                  <div className=" dashboard-chart-sub">
                    Today Total Leads
                  </div>

                </div>
              </div>
              <div className="col-md-10 ">
                <Line data={lineData} options={lineOptions} />

              </div>
            </div>
          </div>
        </div>
        <div className=" col-md-6 p-4 ps-1">
          <div className="chart-card p-3">
            <div className="row mb-3 ">
              <div className="h5 fw-bolder">Monthly Lead</div>
            </div>
            <div className="row">
              <div className="col-1 align-items-center d-flex" onClick={() => setShow(false)}>
                <Icon name="angle left" size="big" hidden={!show} />
              </div>
              <div className="col-10 h-100 mx-auto">
                <Bar data={barData} options={barOptions} />
              </div>
              <div className="col-1 align-items-center d-flex" onClick={() => setShow(true)}>
                <Icon name="angle right" size="big" hidden={show} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row px-1 mt-0">
        <div className="col-md-6 p-4 ps-0">
          <DashboardSourceFilter 
            {...{
              filter,
              filterOptions,
              sourceData,
              sourceCount
            }}
          />
        </div>
        <div className="p-4 col-md-6 ps-1">
          <div className="chart-card p-3">

            <div className="row mb-3 ">
              <div className="col-6 h5 fw-bolder">Lead Status Chart</div>
              <div className="col-6 d-flex justify-content-end">
                <div className="dashboard-filter-view">
                  {filter ? filterOptions[filter] : filterOptions[0]}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 my-auto">
                <div className="">
                  <table className="m-auto pie-chart-text-container">
                    <tbody>
                      <tr>
                          <td><button className="button ui px-3 py-2 chart-badge1"></button></td>
                          <td className="ps-0 chart-badge-text">New Lead</td>
                          <td className="ps-0 chart-badge-text">-</td>
                          <td className="ps-0 chart-badge-text">{statusCount?statusCount['new']:0}</td>
                      </tr>
                      <tr>
                          <td><button className="button ui px-3 py-2 chart-badge2"></button></td>
                          <td className="ps-0 chart-badge-text">Open</td>
                          <td className="ps-0 chart-badge-text">-</td>
                          <td className="ps-0 chart-badge-text">{statusCount?statusCount['open']:0}</td>
                      </tr>
                      <tr>
                          <td><button className="button ui px-3 py-2 chart-badge6"></button></td>
                          <td className="ps-0 chart-badge-text">Walk In</td>
                          <td className="ps-0 chart-badge-text">-</td>
                          <td className="ps-0 chart-badge-text">{statusCount?statusCount['walk_in']:0}</td>
                      </tr>
                      <tr>
                          <td><button className="button ui px-3 py-2 chart-badge3"></button></td>
                          <td className="ps-0 chart-badge-text">Rejected</td>
                          <td className="ps-0 chart-badge-text">-</td>
                          <td className="ps-0 chart-badge-text">{statusCount?statusCount['rejected']:0}</td>
                      </tr>
                      <tr>
                          <td><button className="button ui px-3 py-2 chart-badge4"></button></td>
                          <td className="ps-0 chart-badge-text">Closed</td>
                          <td className="ps-0 chart-badge-text">-</td>
                          <td className="ps-0 chart-badge-text">{statusCount?statusCount['closed']:0}</td>
                      </tr>
                      <tr>
                          <td><button className="button ui px-3 py-2 chart-badge5"></button></td>
                          <td className="ps-0 chart-badge-text">Not Responded</td>
                          <td className="ps-0 chart-badge-text">-</td>
                          <td className="ps-0 chart-badge-text">{statusCount?statusCount['unavailable']:0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6">
                <div className="">
                  <Doughnut data={pieData} options={pieOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className=" col-md-5 p-4">
                    <div className="chart-card p-3">
                        <div className="row mb-3 ">
                            <div className="h5 fw-bolder">Monthly Lead</div>
                        </div>
                        <div className="row">
                            <div className="col-md-10 mx-auto">

                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  )
}

export default DashboardCompany