import React from "react";
import AgentTaskCard from "./AgentTaskCard";

const AgentTaskList = ({data,auth,refresh}) => {
  return (
    <div className="row mt-3 ms-1 mb-3 me-0">
      <div className="col-lg-12 mb-2">
        {window.innerWidth > 768 && (
          <div className={`lead-title-card col-12 row p-3 pe-4 me-0 align-items-center ${auth.theme == 'companyTheme'? '': 'agent'}`}>
            <div className="col-1 serial-number agent-task-head">
              Sl.
              <br />
              no
            </div>
            <div className="col-3 agent-task-head">Name</div>
            <div className="col-2 agent-task-head">Dead Line</div>
            <div className="col-3 agent-task-head">Task Description</div>
            <div className="col-2 agent-task-head">Status</div>
            <div className="col-1 agent-task-head"></div>
          </div>
        )}
      </div>
      {data?data?.map((task, index)=><AgentTaskCard auth={auth} refresh={refresh} data={task} key={index} index={index} />)
      :<div className="h5 mt-5">Not tasks yet ...</div>
      }
    </div>
  );
};

export default AgentTaskList;
