
import { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Navigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import useAuth from '../../../hooks/useAuth';
import ChartDataLabels from "chartjs-plugin-datalabels";

import useBaseServices from '../../../services/baseServices';
import {
  Chart as ChartJS,
  RadialLinearScale,
  Filler,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

const LeadReport = () => {

  ChartJS.register(ChartDataLabels,RadialLinearScale, ArcElement, Tooltip, Legend,Filler);

  const [leadList, setLeadList] = useState()

  const [months, setMonths] = useState()
  const [closed, setClosed] = useState()
  const [open, setOpen] = useState()
  const [unavailable, setUnavailable] = useState()
  const [paused, setPaused] = useState()
  const [rejected, setRejected] = useState()
  const [walkIn, setWalkIn] = useState()
  const [total, setTotal] = useState()
  const [show, setShow] = useState(1)

  const [filter, setFilter] = useState("Filters")
  
  const {auth} = useAuth()
  
  const { getLeadMonthlyReport} = useBaseServices()
  
  useEffect(()=>{
    // getLeadList(auth.role)
    getLeadChart(auth.role)
  },[]);


  // const getLeadList = async (user) => {
  //   const response = await getReportLeadList(user)
  //   setLeadList(response.data.leads)
  // }
  const getLeadChart = async (user) => {
    const response = await getLeadMonthlyReport(user)
    var data = response.data
    setMonths(data['months'])
    setClosed(data['closed'])
    setOpen(data['open'])
    setRejected(data['rejected'])
    setUnavailable(data['unavailable'])
    setPaused(data['paused'])
    setWalkIn(data['walk_in'])
    setTotal(data['total'])
  }

  const shows = (val) =>{
    if(window.innerWidth>768){
      return show===2?val&&val.slice(0,6):val&&val.slice(6,)
    }
    return show===1?val&&val.slice(9, ):show===4?val&&val.slice(6 ,9):show===3?val&&val.slice(3,6):val&&val.slice(0,3)
  }
 
  
  const barData = {
    labels:shows(months),
    datasets: [
      {
        label: 'Closed',
        backgroundColor: '#35D40D',
        data: shows(closed),
        borderRadius: {
          topRight: 10,
          topLeft: 10,},
        barPercentage: 1.26,
        borderColor: "rgba(0,0,0,0)",
        borderWidth: 5,
      maxBarThickness: 40,
      },
      {
        label: 'Rejected',
        backgroundColor: '#D31C1C',
        data: shows(rejected),
        borderRadius: {
          topRight: 10,
          topLeft: 10,},
        barPercentage: 1.26,
        borderColor: "rgba(0,0,0,0)",
        borderWidth: 5,
      maxBarThickness: 40,
      },
      {
        label: 'Unavailable',
        backgroundColor: '#DEC910',
        data: shows(unavailable),
        borderRadius: {
          topRight: 10,
          topLeft: 10,},
        barPercentage: 1.26,
        borderColor: "rgba(0,0,0,0)",
        borderWidth: 5,
      maxBarThickness: 40,
      },
      {
        label: 'Paused',
        backgroundColor: '#9745FF',
        data: shows(paused),
        borderRadius: {
          topRight: 10,
          topLeft: 10,},
        barPercentage: 1.26,
        borderColor: "rgba(0,0,0,0)",
        borderWidth: 5,
        maxBarThickness: 40,
      },
      {
        label: 'Walk In',
        backgroundColor: '#ff8500',
        data: shows(walkIn),
        borderRadius: {
          topRight: 10,
          topLeft: 10,},
        barPercentage: 1.26,
        borderColor: "rgba(0,0,0,0)",
        borderWidth: 5,
        maxBarThickness: 40,
      },
      {
        label: 'Opened',
        backgroundColor: '#25B4D3',
        data: shows(open),
        borderRadius: {
          topRight: 10,
          topLeft: 10,},
        barPercentage: 1.26,
        borderColor: "rgba(0,0,0,0)",
        borderWidth: 5,
        maxBarThickness: 40,
      },
      {
        label: 'New',
        backgroundColor: '#000000',
        data: shows(total),
        borderRadius: {
          topRight: 10,
          topLeft: 10,},
        barPercentage: 1.26,
        borderColor: "rgba(0,0,0,0)",
        borderWidth: 5,
        maxBarThickness: 40,
      },
    ]
  }


const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    options:{
      layout:{
        padding:{
          top:50
        }
      },
    },
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        formatter: Math.round,
        anchor: "end",
        offset: -15,
        align: "start"
      },
      legend: {
        position: 'top',
        labels:{
          boxWidth:15,
          padding:30,
          useBorderRadius:true,
          borderRadius:2,
          font: {
            size:15,
          },
        },
        title:{
          display:false,
          font:{
            size:78
          }
        },
      }, 
    },
    scales:{
     x:{
      grid:{display:true,},
    },
    y:{
      grid:{display:true,drawTicks:false},
      ticks:{
        display:false
      },
     },
    },
  };


  const incShow =  (val) =>{
    if(val<4){
    val = val+1}
  setShow(val)
  // console.log(show)
}

const decShow =  (val) =>{
  if(val>1){
  val = val-1}
  setShow(val)
  // console.log(show)
}

    return (
      auth.isParent?
        <div className=" ps-0">
          <div className="row px-0 mb-3">
            <div className="report-pane-staff p-4 pe-3 ">
              <div className="row mb-3">
                <div className="col-md-8 report-staff-chart-title">
                  Monthly Leads
                </div>
              </div>
              <div className="row d-flex flex-row align-items-center">
              <div className="col-1 align-items-end justify-content-end h-100 pt-5 mt-5 d-flex" onClick={()=>decShow(show)}>
                <Icon name="angle left" size="big" hidden={show===1}/>
              </div>
              <div className="col-10 mx-auto" style={{height:350,}}>
                <Bar data={barData} options={barOptions} />
              </div>
              <div className="col-1 align-items-center h-100 pt-5 mt-5 pe-4" onClick={()=>incShow(show)}>
                <Icon name="angle right" size="big" hidden={window.innerWidth>768?show===2:show===4}/>
                </div>
            </div>
              {/* <div className="row p-3 pe-4 h-100">
                  <div style={{alignItems:"center",justifyContent:"end"}} className="d-flex h-100 col-md-1" onClick={()=>setShow(false)}>
                    <Icon name="angle left" size="big" hidden={!show}/>
                  </div>
                  <div className='col-md-10 p-4'style={{height:350,}}>
                    <Bar data={barData} options={barOptions} />
                  </div>
                  <div style={{alignItems:"center",justifyContent:"start"}} className="d-flex h-100 col-md-1" onClick={()=>setShow(true)}>
                    <Icon name="angle right" size="big" hidden={show}/>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row mb-3">
            {/* <div className="report-pane-staff p-4"> */}
              {/* <div className="row">
                <div className="report-staff-chart-title col-md-6">Monthly Lead List</div> */}
                {/* <div className="row pe-1">
        <div id="leadsearch" className="row z-index-n1 pe-1"> */}
          {/* {auth?.role === "company" &&
            <div id="searchLead" className="col-3 me-0 pe-1 my-3">
              <div className="me-0">
              <Search
                input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                className="lead-searchBox"
                placeholder="Search Here..."
                size='large'
                fluid={true}

                onResultSelect={}
                onSearchChange={handleSearch}
              />
              </div>
            </div>} */}
          {/* <div className="col-md-2 my-3">
            <DropdownButton id="dropdown-basic-button" title={filter}>
              <Dropdown.Item onClick={() => setFilter("Status")}>Status</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter("Followup")}>Followup</Dropdown.Item> */}
              {/* <Dropdown.Item onClick={() => setFilter("Specific day")}>Specific day</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter("Date Range")}>Date Range</Dropdown.Item> */}
            {/* </DropdownButton>
          </div> */}
          {/* {filter === "Specific day" && <div className="col-md-2 my-3">
            <DatePicker
              className='lead-datepicker col-12'
            />
          </div>} */}
          {/* {filter === "Date Range" && 
          <><div className="col-md-2 my-3">
          <DatePicker
            className='lead-datepicker col-12'
          />
        </div><div className="col-md-2 my-3">
            <DatePicker
              className='lead-datepicker col-12'
            />
          </div></>
          } */}
        {/* </div>
      </div> */}
                {/* <div className="col-md-3">
                  <Search className='report-staff-chart-sub'
                    input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                    placeholder="Search Here..."
                    // onResultSelect={}
                    size='mini'
                    onSearchChange={handleSearch}

                  />
                </div>
                <div className="col-md-3">
                  <Dropdown
                    className='report-staff-drop report-staff-chart-sub'
                    selection
                    fluid={true}
                    placeholder="Select status"
                    options={dropDownItems} />
                </div> */}
              </div>
              {/* <div className="row p-3">
                <div className="report-pane-table p-3 ">
                  <div className="table-responsive">
                    <table className="table table-striped ">
                      <thead>
                        <tr className='bg-dark'>
                          <td>Sl no.</td>
                          <td>Name</td>
                          <td>Email</td>
                          <td>Phone number</td>
                          <td>Interested</td>
                          <td>Followup Stage</td>
                          <td>Status</td>
                        </tr>
                      </thead>
                      <tbody>
                        {leadList && leadList.map(
                          (data, index) => {
                            return (
                              auth.role==='Company'?
                                // data.status != 'New'?
                                <tr className='status-container ' key={index}>
                                <td>{index + 1}</td>
                                <td>{data.name}</td>
                                <td>{data.email}</td>
                                <td>{data.mobile}</td>
                                <td>{data.interest}</td>
                                <td>{data.follow_up}</td>
                                <td><div className={' '+ data.status}>{data.status}</div></td>
                                </tr>
                                // :<></>
                              :
                                // data.lead.status != 'New'?
                                <tr className='status-container ' key={index}>
                                  <td>{index + 1}</td>
                                  <td>{data.lead.name}</td>
                                  <td>{data.lead.email}</td>
                                  <td>{data.lead.mobile}</td>
                                  <td>{data.lead.interest}</td>
                                  <td>{data.lead.follow_up}</td>
                                  <td><div className={' '+ data.lead.status}>{data.lead.status}</div></td>
                                </tr>
                                // :<></>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div> */}
            {/* </div> */}
          </div>
        // </div>
      :
      <Navigate to="/"/>
  )
}
export default LeadReport