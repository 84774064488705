import React from 'react'
import '../../Admin.css'
import { TiTick } from 'react-icons/md'

import useAdminServices from '../../../../services/adminServices'
import { useState } from 'react'
import { useEffect } from 'react'

const AdminCompanyList = () => {
  const [companyList, setCompanyList] = useState()

  const {
    getDemoList
  } = useAdminServices()

  const getCompanyListData = async () => {
    try {
      const response = await getDemoList()
      if (response?.success) {
        setCompanyList(response?.data)
      }
    } catch { }
  }

  useEffect(() => {
    getCompanyListData()
  }, [])

  return (
    <div className='company-list-container'>
      <div className="company-list-card">
        <div className="company-list-header row mx-0">
          <div className="fs-5 col-6 px-4 pt-4">Demo Requests</div>
        </div>
        <div className="company-list-table row mx-0 bg-white">
          <div className="title border-bottom row bg-primary rounded text-white mx-0 px-0 mb-1">
            <div className="col-1 px-2 text-center">Sl.no</div>
            <div className="col-1 pe-4 ">Created At</div>
            <div className="col-2 px-2">Name</div>
            <div className="col-2 px-2">Mobile</div>
            <div className="col-2 px-2">Password</div>
            <div className="col-2 px-2">Email</div>
            <div className="col-1 px-2">Verified</div>
          </div>
          <div className="content row mx-0 px-0">
            {companyList && companyList.map((item, index) => {
              return (
                <div key={index} className="item col-12 row shadow-sm mx-0 px-0 my-2">
                  <div className="col-1 px-2 text-center">{index + 1}</div>
                  <div className="col-1 pe-4 ">{new Date(item?.created_at).toLocaleString().split(',')[0]}</div>
                  <div className="col-2 px-0 overflow-hidden">{item?.name}</div>
                  <div className="col-2 px-2">+{item?.mobile}</div>
                  <div className="col-2 px-2">{item?.password}</div>
                  <div className="col-2 px-2 overflow-hidden">{item?.email}</div>
                  <div className="col-1 px-2"> 
                    <div className='rounded-pill text-white p-1 text-center w-50' style={{backgroundColor:item.is_verified ? 'green' : 'red'}}>
                      {item.is_verified?"YES":"NO"}
                      </div>
                    </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminCompanyList
