import { useEffect } from "react";
import { useState } from "react";
import { Form, Modal, Nav, Tab } from "react-bootstrap";
import Swal from "sweetalert2";
import useLeadServices from "../../../services/leadServices";
import CustomerAddForm from "./CustomerAddForm";
import CustomerProfile from "../../customer/components/CustomerProfile";
import LeadFieldList from "./LeadFieldList";
import CallLogs from "../../../components/calls/CallLogs";
import { FiPhoneCall } from "react-icons/fi";
import FollowUpLog from "../../folowups/components/FollowUpLog";
import { RxUpdate } from "react-icons/rx";
import FollowUpForm from "../../folowups/components/FollowUpForm";

const LeadCard = ({
  index,
  data,
  auth,
  updateLeads,
  getFieldTypeList,
  fieldTypeList,
}) => {
  const [descModal, setDescModal] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleSelectAllClick = () => {
    setSelectAllChecked(!selectAllChecked);
    if (!selectAllChecked) {
      // If select all is checked, select all lead IDs
      const allLeadIds = [data.map((lead) => lead.id)];
      setSelectedLeadIds(allLeadIds);
    } else {
      // If select all is unchecked, deselect all lead IDs
      setSelectedLeadIds([]);
    }
  };

  {
    /*get lead id*/
  }

  const [key, setKey] = useState("tab1");

  const {
    putLeadStatusChange,
    getClick2Call,
    postLeadFieldContent,
    getNextFollowUpCount,
  } = useLeadServices();

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [mail, setMail] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [course, setCourse] = useState("");
  const [location, setLocation] = useState("");
  const [source, setSource] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [count, setCount] = useState("");
  const fieldData = new FormData();

  const loadData = () => {
    setId(data.id);
    setName(data.name);
    if (auth.isProtected == "true" && auth.role === "Agent") {
      setMobile(data.mobile.slice(0, 2) + "******" + data.mobile.slice(-2));
      setMail(
        data.email
          ? data.email.slice(0, 2) +
              "*".repeat(data.email.length - 12) +
              data.email.slice(-10)
          : ""
      );
    } else {
      setMobile(data.mobile);
      setMail(data.email);
    }
    setCreatedBy(data.created_user);
    setAssignedTo(data.assigned_user);
    setCourse(data.interest);
    setLocation(data.location);
    setStatus(data.status);
    setSource(data.source);
    setDescription(data.description);
  };
  useEffect(() => {
    loadData();
  }, [data]);
  const handleCloseDesc = () => setDescModal(false);

  const handleSubmit = async (key, e) => {
    e && e.preventDefault();
    var datas = null;
    // console.log(e.target.value)
    if (key === "status") {
      setStatus(e.target.value);
      datas = JSON.stringify({
        status: e.target.value,
      });
    } else if (key === "description") {
      datas = JSON.stringify({
        description: description,
      });
    }
    try {
      const response = await putLeadStatusChange(id, (data = datas));
      console.log(response);
      if (response?.success) {
        Swal.fire({
          title: "Success",
          text: response?.message,
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        handleCloseDesc();
        updateLeads();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (e) {
      Swal.fire("", e.response.data.message, "error");
    }
  };

  const getDate = (data) => {
    return new Date(data).toString().slice(4, 15);
  };
  const handleClose = () => setShowProfile(false);

  const handleCall = async () => {
    if (auth.isSwitch == "true") {
      Swal.fire({
        text: "Unauthorized Action",
        showConfirmButton: false,
        icon: "error",
        timer: 1000,
      });
    } else {
      try {
        const response = await getClick2Call(data?.mobile);
        if (response?.success) {
          // console.log(response?.data)
          let timerInterval;
          Swal.fire({
            html: '<b>Calling <div className="loader"><span className="dot dot-1"></span><span className="dot dot-2"></span><span className="dot dot-3"></span></div> </b>',
            timer: 2500,
            timerProgressBar: false,
            showConfirmButton: false,

            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        } else {
          Swal.fire("Error!!!", response?.message, "error");
        }
      } catch (e) {
        Swal.fire("", "Something went wrong!!", "error");
      }
    }
  };

  const handleFieldEdit = async (e) => {
    e.preventDefault();
    try {
      const response = await postLeadFieldContent(data?.id, fieldData);
      // console.log(response?.data)
      if (response?.success) {
        Swal.fire("Success", response?.message, "success");
        handleClose();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {
      Swal.fire("", "Something went wrong!!", "error");
    }
    // console.log(datas, leadId)
  };

  const handleFollowUpModal = async () => {
    try {
      const response = await getNextFollowUpCount(data?.id);
      if (response?.success) {
        setCount(response?.data);
      }
    } catch {}
    setDescModal(true);
  };
  {
    /*get lead id function*/
  }
  const handleCheckboxClick = (leadId) => {
    if (leadId) {
      let tempList = [...selectedLeadIds];
      let ind = tempList.findIndex((x) => leadId === x);
      if (ind > -1) tempList.splice(ind, 1);
      else tempList.push(leadId);
      setSelectedLeadIds([...tempList]);
    }
  };
  console.log(selectedLeadIds);

  return (
    <div className="col-lg-12 me-5 ">
      <Modal
        size="lg"
        centered
        show={descModal}
        onHide={handleCloseDesc}
        contentClassName="followup-desc-modal px-5"
      >
        <Modal.Body>
          <FollowUpForm
            id={data?.id}
            count={count}
            handleCloseDesc={handleCloseDesc}
          />
        </Modal.Body>
      </Modal>
      {window.innerWidth > 768 ? (
        <div className="lead-card col-12 row p-3 pe-4 me-3 ">
          <div className="col pe-0 py-1 my-2">
            <input
              type="checkbox"
              checked={
                selectedLeadIds.findIndex((x) => x === data.id) > -1
                  ? true
                  : false
              }
              onClick={() => handleCheckboxClick(data.id)}
            />{" "}
            {/* checkbox */}
          </div>
          <div className="col col-1 pe-0 py-1 my-2  lead-card-title">
            {index}
          </div>
          <div
            className="col my-2 lead-card-title p-1 px-0 overflow-auto mouse-pointer text-primary"
            onClick={(e) => setShowProfile(true)}
          >
            {name}
          </div>
          <div className="col-1 my-2 lead-card-title p-1 pe-0 ps-2">
            {mobile}
          </div>
          <div className="col-1 my-1 lead-card-title p-1 pe-0 ps-5">
            <FiPhoneCall
              size="28px"
              className="btn call-onclick"
              onClick={handleCall}
            />
          </div>
          <div className="col-1 col-2 my-2 lead-card-title p-1 ps-1 pe-0 overflow-auto">
            {createdBy}
          </div>
          <div className="col-1 col-2 my-2 lead-card-title p-1 ps-1 pe-0 overflow-auto">
            {assignedTo}
          </div>
          {/* <div className="col-3 my-2 lead-card-title p-1 ps-0 pe-0 overflow-auto">{mail}</div> */}
          <div className="col-1 my-2 lead-card-title p-1 ps-0 overflow-auto">
            {getDate(data?.created_at)}
          </div>
          {/* <div className="col-1 my-2 lead-card-title p-1 ps-0 overflow-auto">{getDate(data?.updated_at)}</div> */}
          <div className="col-1 my-2 lead-card-title p-1 ps-2 overflow-auto">
            {source}{" "}
            <div className="">
              {data.source == "Website"
                ? data.sub_source
                  ? data.sub_source
                  : ""
                : ""}
            </div>
          </div>
          <div className="col-1 my-2 lead-card-title p-1 pe-0 ps-1 overflow-hidden ps-2">
            {course}
          </div>
          <div className="col-1 my-2 lead-card-title p-1 pe-0 ps-2 overflow-hidden">
            {location}
          </div>
          <div className="col-1 lead-card-title my-2 px-0">
            <div className="">
              <Form.Select
                value={status}
                onChange={(e) => handleSubmit("status", e)}
                className="status-dropdown"
                size="sm"
                disabled={
                  (status === "Closed" && auth.role !== "Company") ||
                  auth.isSwitch == "true"
                    ? true
                    : false
                }
              >
                <option className="menu" value="New">
                  New
                </option>
                <option className="menu" value="Open">
                  Open
                </option>
                <option className="menu" value="Closed">
                  Closed
                </option>
                <option className="menu" value="Walk In">
                  Walk In
                </option>
                <option className="menu" value="Paused">
                  Paused
                </option>
                <option className="menu" value="Rejected">
                  Rejected
                </option>
                <option className="menu" value="Unavailable">
                  Unavailable
                </option>
              </Form.Select>
            </div>
          </div>
        </div>
      ) : (
        <div className="followup-card responsive col-12 p-3 pe-0 my-2">
          <table className=" my-2 followup-card-title followup-data p-1 pe-5 ps-3 ">
            <tbody>
              <tr>
                <th>Name</th>
                <td>
                  <div
                    className=" overflow-auto mouse-pointer text-primary"
                    onClick={(e) => setShowProfile(true)}
                  >
                    {data?.name}
                  </div>
                </td>
              </tr>
              <tr>
                <th>Phone</th>
                <td className="d-flex align-items-center">
                  <div className="col-10 my-2">{mobile}</div>
                  <div className="col-2">
                    <FiPhoneCall
                      size="28px"
                      className="btn call-onclick"
                      onClick={handleCall}
                    />
                  </div>
                </td>
              </tr>
              {/* <tr>
                                <th>E-mail</th>
                                <td className=" overflow-scroll">{mail}</td>
                            </tr> */}
              <tr>
                <th>Created By</th>
                <td className=" overflow-scroll">{createdBy}</td>
              </tr>
              <tr>
                <th>Assigned To</th>
                <td className=" overflow-scroll">{assignedTo}</td>
              </tr>
              <tr>
                <th className="">Created On</th>
                <td>{getDate(data?.created_at)}</td>
              </tr>
              <tr>
                <th>Source</th>
                {/* <td>{getDate(data?.updated_at)}</td> */}
                <td>{source}</td>
              </tr>
              <tr>
                <th>Interested</th>
                <td>{course}</td>
              </tr>
              <tr>
                <th>Location</th>
                <td>{location}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>
                  <div className="">
                    <Form.Select
                      value={status}
                      onChange={(e) => handleSubmit("status", e)}
                      className="status-dropdown"
                      disabled={
                        (status === "Closed" && auth.role !== "Company") ||
                        auth.isSwitch == "true"
                          ? true
                          : false
                      }
                      size="sm"
                    >
                      <option className="menu" value="New">
                        New
                      </option>
                      <option className="menu" value="Open">
                        Open
                      </option>
                      <option className="menu" value="Closed">
                        Closed
                      </option>
                      <option className="menu" value="Walk In">
                        Walk In
                      </option>
                      <option className="menu" value="Rejected">
                        Rejected
                      </option>
                      <option className="menu" value="Paused">
                        Paused
                      </option>
                      <option className="menu" value="Unavailable">
                        Unavailable
                      </option>
                    </Form.Select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <Modal
        show={showProfile}
        onHide={handleClose}
        centered={true}
        size="xl"
        contentClassName="lead-modal px-md-4 py-2 p-4"
      >
        <Modal.Body className="">
          <div className="card document-card row">
            <CustomerProfile
              data={data}
              refresh={updateLeads}
              handleProfileClose={handleClose}
              type={"Lead"}
            />
            <div className="col-12">
              <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                <div className="row">
                  <div className="col-12">
                    <Nav variant="pills" className="flex-md-row flex-column">
                      <Nav.Item className="pt-2 px-md-3">
                        <Nav.Link
                          className="py-3  px-md-4 nav-panes"
                          eventKey="tab1"
                        >
                          Details
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="pt-2">
                        <Nav.Link className="py-3 nav-panes" eventKey="tab2">
                          Follow Ups
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="pt-2 px-md-3">
                        <Nav.Link
                          className="py-3 px-md-3 nav-panes"
                          eventKey="tab3"
                        >
                          Call Logs
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="col-12 pt-3 py-0">
                    <Tab.Content>
                      <Tab.Pane eventKey="tab1" className=" pt-0">
                        {auth.isSwitch == "false" && (
                          <div className="col-12 text-end px-4 pt-0 pb-3">
                            <div
                              onClick={(e) => handleFieldEdit(e)}
                              className="btn btn-success col-md-2 col-12"
                            >
                              Update
                            </div>
                          </div>
                        )}
                        <div className="px-2 field-card">
                          <div className="row">
                            {fieldTypeList &&
                              fieldTypeList.map((field, index) => {
                                return (
                                  <LeadFieldList
                                    key={index}
                                    field={field}
                                    id={data.id}
                                    refresh={getFieldTypeList}
                                    datas={fieldData}
                                  />
                                );
                              })}
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tab2">
                        <FollowUpLog
                          handleFollowUpModal={handleFollowUpModal}
                          count={count}
                          handleCloseDesc={handleCloseDesc}
                          descModal={descModal}
                          id={data.id}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="tab3">
                        <CallLogs type={"lead"} content={data} />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
            <div className="col-12 row py-3">
              <div className="col-7 col-md-9"></div>
              <div
                onClick={handleClose}
                className={`btn btn-primary btn-pill col-4 col-md-2 ${
                  auth.theme === "companyTheme" ? "" : "agent"
                }`}
              >
                Close
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LeadCard;
