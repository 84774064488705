import React from 'react'
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'
import useCompanyServices from '../../services/companyServices'
import Swal from 'sweetalert2'
import './passChange.css'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'

export const PassChange = ({userID,setShowPC,showPC}) => {
    const [pass, setPass] = useState("")
    const [pass2, setPass2] = useState("")
    const [passCheck, setPassCheck] = useState(false)
    const [type, setType] = useState('password')
     
    const {putChangeStaffPassword} = useCompanyServices()

    const handleEye = () => {
        type == 'password' ? setType('text'): setType('password');
    }
    const handlePassC = async (e) => {
        e.preventDefault()
        try{
            let data = JSON.stringify({
                password:pass,
                new_password:pass
            })
            let res = await putChangeStaffPassword(userID,data) 
            if(res?.success){
                Swal.fire("", "Password changed !!!", "success")
                handleCancel()
            }else{
                Swal.fire("", "Something wrong happeded !!!", "info")
            }
        }catch(err){
            Swal.fire("", "Something wrong happeded !!!", "error")
        }
    }

    const handlePassCheck = (e) =>{
        let val = e.target.value || ""
        let nam = e.target.name || ""
        if(pass2!="" && nam === "pass1"){
            if(val !== pass2){
            setPassCheck(true)
        }else{
            setPassCheck(false)
        }}
        if(nam === "pass1")setPass(val)
        if(nam === "pass2" && pass !== ""){
            if(val !== pass){
            setPassCheck(true)
        }else if(nam==="pass2"){
            setPassCheck(false)
        }
        if(nam==="pass2") setPass2(val)
    }   
        // nam ==="pass1"&&pass2===val?setPassCheck(false):setPassCheck(true)
        // nam ==="pass2"&&pass===val?setPassCheck(false):setPassCheck(true)
        // val && (pass!=="" || pass2!=="")?setPassCheck(false):setPassCheck(true)
    }
    // let a = ""
    // let b = "";
    // const handlePassCheck = (e) =>{
    //     let val = e.target.value
    //     let nam = e.target.name
    //     if(nam === "pass1") a = val
    //     if(nam === "pass2") b = val
    //     if(a !== b){
    //         setPassCheck(true)
    //     }
    //     if(a === "" || b === "" ){
    //         setPassCheck(false)
    //     }
    //     console.log(a+" && "+b)
    // }


    const handleCancel = () =>{
        setShowPC(false)
        setPassCheck(false)
        setPass("")
        setPass2("")
    }

  return (
    <div>
        <Modal
            show={showPC}
            onHide={()=>setShowPC(false)}
            centered={true}
            size="xs"
            contentClassName=""
            >
            <Modal.Body>
                <form onSubmit={handlePassC}>
            <div className='pass-modal col-12'>
                <div className='col-6'>
                <div className='row pass-modal text-center'>
                    <div className="fw-bold  mb-4">Change Password</div>
                    <div className=' body d-flex row text-start  flex-column'>
                        Type password
                        <div className='passC d-flex flex-row position-relative p-0'> 
                            <input type={type} required placeholder='Password ?' name='pass1'
                            onChange={e=>handlePassCheck(e)} className='password1 first rounded-5 p-1 mt-2 ps-3'/>
                                <div className='eye btn position-absolute rounded-5 px-2' onClick={handleEye}> 
                                    {type === 'password'?<AiOutlineEye size={"1.4rem"}/>:<AiOutlineEyeInvisible size={"1.4rem"}/>}
                                </div>
                        </div> 
                    </div>
                    <div className=' body d-flex row text-start mt-3 flex-column'>
                        confirm password
                        <div className='passC d-flex flex-row position-relative p-0'>
                        <input disabled={pass==""?true:false} type='password' required placeholder='Confirm password !' name='pass2'
                        onChange={e=>handlePassCheck(e)} className='password1 rounded-5 p-1 mt-2 ps-3'/>
                    </div>
                        {passCheck&&<div className='text-danger h6 mb-0'>password not correct</div>}
                    </div>
                    <div className='passC'>
                        <div onClick={handleCancel} className='passC bg-secondary btn mt-4 '>cancel</div>
                        <input type='submit' disabled={passCheck} value={"Change"} className='passC bg-warning btn mt-4'/>
                    </div> 
                </div>
                </div>
            </div>
            </form>
            </Modal.Body>
        </Modal>
    </div>
  )
}
