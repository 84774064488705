import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa'
import Swal from 'sweetalert2';
import TableData from './TableData';
import useLeadServices from '../../../services/leadServices';

const SourceSettings = ({auth}) => {
    const [createModal, setCreateModal] = useState(false)
    const [source, setSource] = useState("")
    const [sourceList, setSourceList] = useState("")

    const { postCreateSource, getAllSource } = useLeadServices()

    useEffect(() => {
        getSourceList()
    }, [])


    const getSourceList = async () => {
        let response = await getAllSource()
        if (response.success) {
            setSourceList(response.data)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            if (source) {
                const data = JSON.stringify({
                    "name": source
                })
                let response = await postCreateSource(data)
                if (response.success) {
                    Swal.fire("", "Source Created Successfully", "success")
                    setSource('')
                    handleCreateClose()
                    getSourceList()
                } else {
                    Swal.fire("", "Some Errors occured", "error")

                }
            }
        } catch (err) {
            Swal.fire("", "Something went wrong!!", "error");
        }

    }

    const handleCreateOpen = () => setCreateModal(true);

    const handleCreateClose = () => setCreateModal(false);
    return (
        <>
            <div className="row position-relative mb-5 ms-4">
                <button onClick={handleCreateOpen} className='btn col-12 col-md-2 position-absolute me-3 btn-pill end-0 btn-success pill '>
                    <FaPlus size="14px" /> Add new
                </button>
                <Modal

                    show={createModal}
                    onHide={handleCreateClose}
                    centered={true}
                    size="md"
                    contentClassName=""
                >
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <div className='p-2'>
                                <h3>Create Source</h3>
                                <div className=" my-3">
                                    <Form.Group className='' controlId='staffName'>
                                        <Form.Label className=''>Source</Form.Label>
                                        <Form.Control value={source} onChange={(e) => setSource(e.target.value)} className='py-2' placeholder="Eg: Meta" />
                                    </Form.Group>
                                </div>
                                <div className="position-relative mb-5">
                                    <div className='position-absolute end-0 row'>
                                        <div onClick={handleCreateClose} className='col btn btn-danger mx-2'>Cancel</div>
                                        <button className={`col btn btn-primary ${auth.theme==='companyTheme'?'':'agent'}`}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            <div className="row my-3">
                <div className="settings-table-container">
                    <div>
                        {window.innerWidth>768&&
                            <div className="row p-2 pe-4 settings-table-header">
                                <div className="col-1">Sl.No</div>
                                <div className="col-8">Source</div>
                                <div className="col-1 ps-3">Edit</div>
                                <div className="col-1 ps-3">Active</div>
                                <div className="col-1 ps-4">Delete</div>
                            </div>
                        }
                    </div>
                    <div className="my-2 settings-table-body">
                        {sourceList && sourceList.map((data, index) =>
                            <TableData data={data} refresh={getSourceList} type={"Source"} index={index} key={index}/>
                        )}
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default SourceSettings;