import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link,useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { setDefaultLocale } from "react-datepicker";

const AccountRegister = (props) => {
  const navigate = useNavigate()
  const { verify, loading, setLoading } = props;
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    password: "",
    confirmpassword: "",
  });
  const [errors, setErrors] = useState({ email: "", password: "" });

  const onInputChange = (name, e) => {
    const value = e.target.value;
    setInputData({ ...inputData, [name]: value });

    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        setErrors({
          ...errors,
          email: "Email must be in the format example@example.com",
        });
      } else {
        setErrors({ ...errors, email: "" });
      }
    }
  };

  const postDetails = async () => {
    // Check if all input fields are filled in
    setLoading(!loading);
    if (
      inputData.name &&
      inputData.email &&
      inputData.password &&
      inputData.confirmpassword
    ) {
      if (inputData.confirmpassword === inputData.password) {
        try {
          const response = await axios.post(
            `https://crm-beta-api.vozlead.in/api/v2/account/company/register/${verify}/confirm/`,
            inputData
          );
          Swal.fire({
            title: "Success!",
            text: response.data.message,
            icon: "success",
          });
          navigate("/");
        } catch (error) {
          console.error("Error posting Details:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "Oops...",
          text: "Password and confirm password did not match!",
        });
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Please Complete the details !",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="h-100 w-100 form-basic">
        <div className="w-100">
          <div className="w-100 mb-2">
            <p className="text-white m-0 mb-2">Enter your name</p>
            <input
              type="text"
              className="input-page1 bg-transparent col-12 px-2"
              onChange={(e) => {
                onInputChange("name", e);
              }}
            />
          </div>
          <div className="w-100 mb-2 ">
            <p className="text-white m-0 mb-2">Enter your Email</p>
            <input
              type="email"
              className="input-page1 bg-transparent col-12 px-2"
              onChange={(e) => {
                onInputChange("email", e);
              }}
              title="Please enter a valid mail id"
            />
            <p className="w-100 mb-2 ">
              {errors.email && (
                <span className="text-error">* {errors.email}</span>
              )}
            </p>
          </div>
          <div className="w-100 mb-2 ">
            <p className="text-white m-0 mb-2">Password</p>
            <input
              type="password"
              className="input-page1 bg-transparent col-12 px-2"
              onChange={(e) => {
                onInputChange("password", e);
              }}
              title="Password must contain atleast a symbol, an uppercase and a lowercase letters"
            />
          </div>
          <div className="w-100 mb-2 ">
            <p className="text-white m-0 mb-2">Confirm Password</p>
            <input
              type="password"
              className="input-page1 bg-transparent col-12 px-2"
              onChange={(e) => {
                onInputChange("confirmpassword", e);
              }}
            />
          </div>
          <div className="w-100 mb-4 d-flex gap-3 align-items-center">
            <input
              type="checkbox"
              className="input-checkbox form-check-input bg-transparent rounded-2 m-0"
            />
            <p className="text-white">
              Agree <span className="register-span">Terms and Conditions</span>
            </p>
          </div>
        </div>
        <button
          className="button-page1 bg-white w-100 mb-2 d-flex align-items-center justify-content-center gap-3"
          onClick={postDetails}
          disabled={loading}
        >
          <HashLoader
            // color={color}
            loading={loading}
            // cssOverride={override}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          Create Account
        </button>
        <p className="text-white">
          Already have an account?
          <Link to="/">
            <span className="login-span"> Log In </span>
          </Link>
        </p>
      </div>
    </>
  );
};

export default AccountRegister;
