import React, { useEffect, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import ContextAwareToggle from "../../../components/accordian/ContextAwareToggle";
import NewFieldForms from "../../../components/forms/NewFieldForms";
import useCompanyServices from "../../../services/companyServices";
import useLeadServices from "../../../services/leadServices";
import useAuth from "../../../hooks/useAuth";

const LeadAddForm = (props) => {
  const { handleClose, refresh } = props;
  const [fieldTypeList, setFieldTypeList] = useState();
  const [sourceList, setSourceList] = useState("");
  const [leadId, setLeadId] = useState("");
  const [leadName, setLeadName] = useState("");
  const [leadEmail, setLeadEmail] = useState("");
  const [leadMobile, setLeadMobile] = useState("");
  const [leadLocation, setLeadLocation] = useState("");
  const [leadSource, setLeadSource] = useState("Other");
  const [leadInterest, setLeadInterest] = useState("");

  const datas = new FormData();

  const { auth } = useAuth();

  const {
    getActiveFieldTypes,
    postLeadFieldContent,
    getActiveSource,
    postNewLead,
  } = useLeadServices();

  useEffect(() => {
    getFieldTypeList();
    getSourceList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = JSON.stringify({
        name: leadName,
        email: leadEmail,
        mobile: leadMobile,
        location: leadLocation,
        source: leadSource,
        interest: leadInterest,
      });
      const response = await postNewLead(data);
      refresh();
      if (response?.success) {
        if (fieldTypeList) {
          setLeadId(response?.data?.id);
        } else {
          Swal.fire("", "Lead created successfully", "success");
          handleClose();
        }
      } else {
        handleClose();
        Swal.fire("", response?.message, "error");
      }
    } catch (err) {
      handleClose();
      var error_data;
      var index = "Error";
      if (err?.response?.data?.errors.includes("error")) {
        error_data = err?.response?.data?.message;
      } else {
        let error = err?.response?.data?.errors;
        index = Object.keys(error)[0];
        error_data = error[index][0];
      }
      Swal.fire({
        title: index.toUpperCase(),
        text: error_data,
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      // console.log(err)
    }
  };

  const getFieldTypeList = async () => {
    try {
      const response = await getActiveFieldTypes();
      // console.log(response?.data)
      if (response?.success) {
        setFieldTypeList(response?.data);
      }
    } catch {}
  };

  const getSourceList = async () => {
    try {
      const response = await getActiveSource();
      // console.log(response?.data)
      if (response?.success) {
        setSourceList(response?.data);
      }
    } catch {}
  };

  const handleFieldSubmit = async (e) => {
    e.preventDefault();
    try {
      if (Array.from(datas.entries()).length != 0) {
        const response = await postLeadFieldContent(leadId, datas);
        if (response?.success) {
          Swal.fire("Success", response?.message, "success");
          handleClose();
        } else {
          Swal.fire("", response?.message, "error");
        }
      } else {
        Swal.fire("Success", "Lead created successfully", "success");
        handleClose();
      }
    } catch {}
  };

  return (
    <div className="">
      <div className="row">
        <div className="h3 fw-bolder">New Lead</div>
      </div>
      <Accordion defaultActiveKey="0">
        <Accordion.Collapse eventKey="0">
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="row my-4">
                  <div className="col-md-8 px-1">
                    <Form.Group className="" controlId="leadName">
                      <Form.Label className="fw-bolder">Name</Form.Label>
                      <Form.Control
                        className="py-2"
                        placeholder={"Enter name"}
                        onChange={(e) => setLeadName(e.target.value)}
                      />
                      <span className="name1">* mandatory field</span>
                    </Form.Group>
                  </div>
                  <div className="col-md-4 px-1">
                    <Form.Group className="" controlId="leadMobile">
                      <Form.Label className="fw-bolder">
                        Mobile Number
                      </Form.Label>
                      <Form.Control
                        className="py-2"
                        placeholder="+919876543210"
                        onChange={(e) => setLeadMobile(e.target.value)}
                      />
                      <span className="name1">* mandatory field</span>
                    </Form.Group>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-5 px-1">
                    <Form.Group className="" controlId="leadSource">
                      <Form.Label className="fw-bolder">Source</Form.Label>
                      {/* <Form.Control className='py-2' placeholder="Direct Enquiry" onChange={(e)=>setLeadSource(e.target.value)}/> */}
                      <Form.Select
                        className="py-2 source-dropdown"
                        size="small"
                        onChange={(e) => setLeadSource(e.target.value)}
                      >
                        {/* <option className='menu' value={null}>select</option> */}
                        {sourceList &&
                          sourceList.map((data, index) => {
                            return (
                              <option
                                key={index}
                                className="menu"
                                value={data.name}
                              >
                                {data.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-7 px-1">
                    <Form.Group className="" controlId="leadMail">
                      <Form.Label className="fw-bolder">Email</Form.Label>
                      <Form.Control
                        className="py-2"
                        placeholder="Enter email"
                        onChange={(e) => setLeadEmail(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-7 px-1">
                    <Form.Group className="" controlId="leadLocation">
                      <Form.Label className="fw-bolder">Location</Form.Label>
                      <Form.Control
                        className="py-2"
                        placeholder="landmark"
                        onChange={(e) => setLeadLocation(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-5 ">
                    <Form.Group className="" controlId="leadCourse">
                      <Form.Label className="fw-bolder">Course</Form.Label>
                      <Form.Control
                        className="py-2"
                        placeholder="Enter course"
                        onChange={(e) => setLeadInterest(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            <div className="row staff-btn-container">
              <div className="col-md-6">
                <div className="row d-flex justify-content-end">
                  <div className="col-md-6 pb-2">
                    <div
                      onClick={handleClose}
                      className="btn btn-outline-primary staff-btn col-12"
                    >
                      Cancel
                    </div>
                  </div>
                  {leadName != "" && leadMobile != "" && (
                    <div className="col-md-6">
                      {fieldTypeList ? (
                        <ContextAwareToggle eventKey="1">
                          <button
                            type="submit"
                            className={`btn btn-primary staff-btn col-12 ${
                              auth.theme === "companyTheme" ? "" : "agent"
                            }`}
                          >
                            Next
                          </button>
                        </ContextAwareToggle>
                      ) : (
                        <button
                          type="submit"
                          className={`btn btn-primary staff-btn col-12 ${
                            auth.theme === "companyTheme" ? "" : "agent"
                          }`}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </Accordion.Collapse>
        <Accordion.Collapse eventKey="1">
          <Form onSubmit={handleFieldSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="row my-4">
                  {fieldTypeList &&
                    fieldTypeList.map((data, index) => {
                      return (
                        <div key={index} className="col-md-6 px-1 pb-2">
                          <NewFieldForms
                            data={data}
                            key={index}
                            datas={datas}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="row staff-btn-container">
              <div className="row col-md-6">
                <div className="col-md-6">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="btn btn-outline-primary staff-btn col-12"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    type="submit"
                    className={`btn btn-primary staff-btn col-12 ${
                      auth.theme === "companyTheme" ? "" : "agent"
                    }`}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
};

export default LeadAddForm;
