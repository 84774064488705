import './Settings.css'
import { Navigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import { Modal, Nav, Tab } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import StatusSettings from './components/StatusSettings'
import DocumentSettings from './components/DocumentSettings'
import InterestSettings from './components/InterestSettings'
import FieldSettings from './components/FieldSettings'
import CallSettings from './components/CallSettings'
import { Dropdown } from 'semantic-ui-react'
import SourceSettings from './components/SourceSettings'
import OtherSettings from './components/OtherSettings'
import Integrations from './components/Integrations'

const Settings = () => {
    const [createModal, setCreateModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)

    const { auth } = useAuth()


    const handleCreateOpen = () => setCreateModal(true);
    const handleViewOpen = () => setViewModal(true);

    const handleCreateClose = () => setCreateModal(false);
    const [key, setKey] = useState("tab1");
    const [selected, setSelected] = useState("Customer Status Settings");

    const handleSelected = (data) => {
        switch (data) {
            case "1":
                return setSelected("Customer Status Settings");
            case "2":
                return setSelected("Document Settings");
            case "3":
                return setSelected("Product Settings");
            case "4":
                return setSelected("Field Settings");
            case "5":
                return setSelected("Call Settings");
            case "6":
                return setSelected("Lead Source Settings");
            case "7":
                return setSelected("Integrations");
        }
    }

    const handleViewClose = () => setViewModal(false);
    return (
        auth.role==='Company' ?
            <div className='ms-3 ms-md-0'>
                <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                    <div className="row  me-2">
                        <div className="col-md-3">
                            <Nav variant="pills" className="flex-column d-none d-md-block">
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' onClick={() => handleSelected("1")} eventKey="tab1">Customer Status</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' onClick={() => handleSelected("2")} eventKey="tab2">Documents</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' onClick={() => handleSelected("3")} eventKey="tab3">Products</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' onClick={() => handleSelected("4")} eventKey="tab4">Lead Form Fields</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' onClick={() => handleSelected("5")} eventKey="tab5">Call Settings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' onClick={() => handleSelected("6")} eventKey="tab6">Lead Sources</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' onClick={() => handleSelected("7")} eventKey="tab7">Integrations</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' onClick={() => handleSelected("8")} eventKey="tab8">Other</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <div className="col-md col my-3 ms-md-1 ms-3 pe-0 d-block d-md-none">
                                <Dropdown
                                    className={`btn btn-sm btn-primary ${auth.theme==='companyTheme'?'':'agent'}`}
                                    text={selected}

                                >
                                    <Dropdown.Menu className='mt-2 p-3'>
                                        <Dropdown.Item >
                                            <Nav.Link className='' onClick={() => handleSelected("1")} eventKey="tab1">Customer Status Settings</Nav.Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item >
                                            <Nav.Link className='' onClick={() => handleSelected("2")} eventKey="tab2">Document Settings</Nav.Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item >
                                            <Nav.Link className='' onClick={() => handleSelected("3")} eventKey="tab3">Product Settings</Nav.Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item >
                                            <Nav.Link className='' onClick={() => handleSelected("4")} eventKey="tab4">Field Settings</Nav.Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item >
                                            <Nav.Link className='' onClick={() => handleSelected("5")} eventKey="tab5">Call Settings</Nav.Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item >
                                            <Nav.Link className='' onClick={() => handleSelected("6")} eventKey="tab6">Lead Source Settings</Nav.Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item >
                                            <Nav.Link className='' onClick={() => handleSelected("7")} eventKey="tab7">Integrations</Nav.Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item >
                                            <Nav.Link className='' onClick={() => handleSelected("8")} eventKey="tab8">Other</Nav.Link>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-md-9 settings-card p-3">
                            <Tab.Content>
                                <Tab.Pane eventKey="tab1">
                                    <div>
                                        <StatusSettings {...{auth}} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab2">
                                    <div>
                                        <DocumentSettings {...{auth}} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab3">
                                    <div>
                                        <InterestSettings {...{auth}} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab4">
                                    <div>
                                        <FieldSettings {...{auth}} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab5">
                                    <div>
                                        <CallSettings {...{auth}} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab6">
                                    <div>
                                        <SourceSettings {...{auth}} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab7">
                                    <div>
                                        <Integrations {...{auth}} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab8">
                                    <div>
                                        <OtherSettings {...{auth}} />
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
            :
            <Navigate to="/" />
    )
}

export default Settings

