import React from "react";
import "../register.css";
import { useEffect, useState } from "react";
import OTPInput from "otp-input-react";
import Swal from "sweetalert2";
import axios from "../../../api/axios";
import { Link } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";

const OtpRegister = (props) => {
  const { token, setToken, setVerify, phoneNumber, loading, setLoading } = props;
  const [OTP, setOTP] = useState("");
  const [number, setNumber] = useState("");

  const handleOTPChange = (num) => {
    setOTP(num);
  };

  const postOtp = async () => {
    setLoading(!loading);
    try {
      const response = await axios.post(
        `https://crm-beta-api.vozlead.in/api/v2/account/company/register/${token}/verify/`,
        { otp: OTP }
      );
      console.log("Response from backend:", response.data);
      if (response.data.success) {
        console.log("response", response);
        setLoading(false);
        setVerify(response.data.data.verification_token);
      }
    } catch (error) {
      console.error("Error posting number:", error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });

    }
  };

  const onResendClick = async () => {
    try {
      const response = await axios.post(
        `https://crm-beta-api.vozlead.in/api/v2/account/company/register/`,
        { mobile: phoneNumber, resend: "yes" }
      );
      console.log("Response from backend:", response.data);
      if (response.data.success) {
        Swal.fire({
          // title: "Success!",
          text: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    } catch (error) {
      console.log("Error posting number:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
    }
  };

  return (
    <>
      <div className="mb-3 w-100">
        <p className="text-white">Enter OTP</p>
        <div className="w-100 mb-3 gap-2">
          <OTPInput
            value={OTP}
            onChange={handleOTPChange}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
            className="otp-input d-flex gap-2 mb-4"
          />
        </div>
        <a className="login-span btn" onClick={onResendClick}>
          Resend OTP
        </a>
        <button
          className="button-page1 bg-white mt-3 bg-white d-flex align-items-center justify-content-center gap-3"
          onClick={postOtp}
          style={{ width: "100%" }}
          disabled={loading}
        >
          <HashLoader
            // color={color}
            loading={loading}
            // cssOverride={override}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          Verify
        </button>
      </div>
      <p className="text-white">
        Already have an account? 
        <Link to='/'><span className="login-span">  Log In </span></Link>
      </p>
    </>
  );
};

export default OtpRegister;
